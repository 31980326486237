import React, {  useState , useEffect} from "react";
import { getidb } from "./api/getidb";
import { fetchData } from "./api/fetchData";
import Popup from "reactjs-popup";
import "../../../assets/scss/popupstyles.css";
import "../../../assets/scss/style.css";
import { Label ,Alert,Card, CardBody, CardTitle, CardSubtitle, Input, Table,InputGroup,InputGroupAddon,InputGroupButtonDropdown,DropdownToggle,DropdownMenu,DropdownItem } from "reactstrap";
import { Map as LeafletMap, TileLayer, Marker } from "react-leaflet";
import "../../../assets/scss/sliderstyles.css";
import L from "leaflet";
import markericon from "../../../assets/images/icons/marker-icon-blue.png";
import "react-html5-camera-photo/build/css/index.css";
import ImageUploader from "react-images-upload";
import axios from "axios";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import filterFactory from 'react-bootstrap-table2-filter';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


const Projects = ({ addfm, isOpen }) => {

	const [apikey, setApikey] = useState(false);
	const [groups_nr, setGroupsNr] = useState(false);
	const [groups_name, setGroupsName] = useState("none");
	const fallenmelder = [];
	const [tableset, setTableset] = useState(false);
	const [tabledata, setTable] = useState([]);
	const [results, setResults] = useState([]);

	const [open, setOpen] = useState(false);
	const [openpopup, setOpenPop] = useState(false);
	const [opentrap, setTrap] = useState(false);
	const [position, setPosition] = useState(false);
	const [name, setName] = useState(false);
	const [pics, setPics] = useState(false);
	const [typ, setFallentyp] = useState("none");
	const [group, setGroup] = useState("none");
	const [groupoptions, setGroupOptions] = useState([]);
	const [groups_result, set_Option_Group_Results] = useState([]);
	const [typoptions, setTypOptions] = useState([]);
	const [pictures, setPictures] = useState([]);
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [dropdownOpen2, setDropdownOpen2] = useState(false);	  
	const [visibleAlert, setVisibleAlert] = useState(false);
	const [visibleAlert2, setVisibleAlert2] = useState(false);
	const [ErrorMsg, setErrorMsg] = useState(false);
	const [SuccessMsg, setSuccessMsg] = useState(false);
	const [settingdisable, setDisable] = useState(false);
	const [groupsset, setGroupsFetched] = useState(false);
	const [updateinterval, setUpdateInterval] = useState(false);

	const hash = window.location.hash;

    useEffect(() => {
        console.log('- Has Updated')
		tableadd();
    },[results])

	function tableadd(){
		var result = results
		
		setApikey(result.apikey)
		if(!open){
			setTable([])
			if(result.length !== 0){
				if(!groups_nr && hash.includes("groupid=")){
					setGroupsNr(hash.substring(hash.indexOf('=')+1))
				}
				for (var x = 0; x < result.length; x++) {
					if(groups_nr === "Achtung"){
						if(result[x].status == 1 ||result[x].bat <= 15 || result[x].sig <= 15){
							if(result[x].apikey !== "111"){
								fallenmelder.push({id:[result[x].nr,result[x].name],status:result[x].status,battery:result[x].bat, signal:result[x].sig,last_alert:result[x].time,settings:[result[x].group_name,result[x].lat,result[x].lon,result[x].fallentyp,result[x].apikey,result[x].groupid]});
							}
							else{
								fallenmelder.push({id:[result[x].group_name,result[x].name],status:result[x].status,battery:result[x].bat, signal:result[x].sig,last_alert:result[x].time,settings:[result[x].group_name,result[x].lat,result[x].lon,result[x].fallentyp,result[x].apikey,result[x].groupid]});
							}
							
						}
					}
					else{
						
						if(groups_nr == result[x].groupid || groups_nr === "ALLE" || !groups_nr || groups_nr === result[x].apikey){
							
							if(result[x].apikey !== "111"){
								fallenmelder.push({id:[result[x].nr,result[x].name],status:result[x].status,battery:result[x].bat, signal:result[x].sig,last_alert:result[x].time,settings:[result[x].group_name,result[x].lat,result[x].lon,result[x].fallentyp,result[x].apikey,result[x].groupid]});
							}
							else{
								fallenmelder.push({id:[result[x].group_name,result[x].name],status:result[x].status,battery:result[x].bat, signal:result[x].sig,last_alert:result[x].time,settings:[result[x].group_name,result[x].lat,result[x].lon,result[x].fallentyp,result[x].apikey,result[x].groupid]});
							}
						}
					}

					if (!typoptions.includes(result[x].fallentyp) && result[x].fallentyp !== null) {
						typoptions.push(result[x].fallentyp);
					}
					if (!groupoptions.includes(result[x].group_name) && result[x].group_name !== null) {
						groupoptions.push(result[x].group_name);
						//setGroupOptions({ ...groupoptions, [result[x].revier]: result[x].revier });
					}
				}
				
				setTable(fallenmelder)
			}
			setTableset(true)
		}
		
	

	}

	function tableupdate(){
		getidb().then(function (result) {
				setResults(result)

		})
	}

	function nameformat(cell, row, rowIndex, formatExtraData) {
		return (
			<div class=""><h5 class="mb-0 font-16 font-medium">{cell[1]}</h5><span>{cell[0]}</span></div>
			);
	}
	function staformat(cell, row, rowIndex, formatExtraData) {
		return (
			<i class={ formatExtraData[cell] }></i>
			);
	}
	function batformat(cell, row, rowIndex, formatExtraData) {
		return (
			<div class="batteryContainer"><div class="batteryOuter"><div id="batteryLevel" style={{width:cell + "%"}}></div></div><div class="batteryBump"></div></div>
			);
	}
	function sigformat(cell, row, rowIndex, formatExtraData) {
		if(cell>=60){
			return (
				<ul id="signal-strength"><li class="very-weak"><div></div></li><li class="weak"><div></div></li><li class="strong"><div></div></li><li class="very-strong"><div></div></li></ul>
				);
		}
		else if(cell>=40){
			return (
				<ul id="signal-strength"><li class="very-weak"><div></div></li><li class="weak"><div></div></li><li class="strong"><div></div></li></ul>
				);
		}
		else if(cell>=20){
			return (
				<ul id="signal-strength"><li class="very-weak"><div></div></li><li class="weak"><div></div></li></ul>
				);
		} 
		else{
			return (
				<ul id="signal-strength"><li class="very-weak"><div></div></li></ul>
				);
		}

	}
	function timeformat(cell, row, rowIndex, formatExtraData) {
		let unix_timestamp = cell;
		var date = new Date(unix_timestamp * 1000);
		const date_options = { weekday: "short", month: "short", day: "numeric", hour: "2-digit", minute: "2-digit" };
		const datum = date.toLocaleDateString("de-DE", date_options);
		var formattedTime = datum;
		return (
		<div>{formattedTime}</div>   //onclick={() => push_popup("set", row["id"][1], row["id"][0] , "dbpic", cell[0],cell[1], cell[2],cell[3])}
		);
	}
	function setformat(cell, row, rowIndex, formatExtraData) {
		return (
		<div><a class='fa fa-cog'  ></a> </div>   //onClick={() => push_popup("set", row["id"][0], row["id"][1] , "dbpic", cell[1],cell[2], cell[3],cell[0])}
		);
	}
	function toggleDropDown() {
		setDropdownOpen(!dropdownOpen);
		setDropdownOpen2(false)
	}
	function toggleDropDown2() {
		setDropdownOpen(false);
		setDropdownOpen2(!dropdownOpen2)
	}
	const columns = [{
		dataField: 'id',
		text: 'Identifikation',
		formatter: nameformat,
		sort: true
		
	}, {
		dataField: 'status',
		text: 'Status',
		formatter: staformat,
		sort: true ,
		formatExtraData: {1:'fa fa-circle text-orange',0:'fa fa-circle text-success'}
	}, {
		dataField: 'battery',
		text: 'Akku',
		formatter: batformat,
		sort: true
	}, {
		dataField: 'signal',
		text: 'Signal',
		formatter: sigformat,
		sort: true
	}, {
		dataField: 'last_alert',
		text: 'Letzte Meldung',
		formatter: timeformat,
		sort: true
	}, {
		dataField: 'settings',
		text: '',
		formatter: setformat
	}]
	const defaultSortedBy = [{
		dataField: "id",
		order: "asc"  // or desc
	}]
	var Icon = L.icon({
		iconUrl: markericon,

		iconSize: [25, 41], // size of the icon
		iconAnchor: [10, 41], // point of the icon which will correspond to marker's location
		shadowAnchor: [4, 62], // the same for the shadow
		popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
	})
	const search = async (e) => {
		setGroupsNr(e)
		setTableset(false)
		setTable([])
	}
	function setgetlocation(nr) {
		var lat = false;
		var lon = false;
		navigator.geolocation.getCurrentPosition(function (position) {
		lat = position.coords.latitude;
		lon = position.coords.longitude;
		var URL = "/api/settingfm";
		var settings = {
			nr: nr,
			set: "lat",
			value: lat,
			apikey: apikey,
		};
		fetchData(URL, settings);
		var settings2 = {
			nr: nr,
			set: "lon",
			value: lon,
			apikey: apikey,
		};
		fetchData(URL, settings2);

		const location = [lat, lon];
		setPosition(location);
		setOpenPop("loc");
		});
	}
	function saveSetting() {
		var URL = "/api/settingfm";

		if (name !== "" && name !== null) {
		var settings = {
			nr: opentrap,
			set: "name",
			value: name,
			apikey: apikey,
		};
		fetchData(URL, settings).then(function (result) {
			if (groups_name !== "" && groups_name !== null) {
			var settings = {
				nr: opentrap,
				set: "revier",
				value: groups_name,
				groupid: parseInt(groups_nr),
				apikey: apikey,
			};
			fetchData(URL, settings).then(function (result2) {
				if (typ !== "" && typ !== null) {
					var settings = {
					nr: opentrap,
					set: "fallentyp",
					value: typ,
					apikey: apikey,
					};
					fetchData(URL, settings).then(function (result) {
						doneSave()
					});
				} else {
					doneSave()
				}
				}
			);
			} 
			else if (typ !== "" && typ !== null) {
			var settings = {
				nr: opentrap,
				set: "fallentyp",
				value: typ,
				apikey: apikey,
			};
			fetchData(URL, settings).then(function (result) {
				doneSave()
			});
			} else {
				doneSave()
			}
		});
		} 
		else if (groups_name !== "" && groups_name !== null) {
		var settings = {
			nr: opentrap,
			set: "revier",
			value: groups_name,
			groupid: parseInt(groups_nr),
			apikey: apikey,
		};
		fetchData(URL, settings).then(
			fetchData(URL, settings).then(function (result2) {
			if (typ !== "" && typ !== null) {
				var settings = {
				nr: opentrap,
				set: "fallentyp",
				value: typ,
				apikey: apikey,
				};
				fetchData(URL, settings).then(function (result) {
					doneSave()
				});
			} else {
				doneSave()
			}
			})
		);
		} 
		else if (typ !== "" && typ !== null) {
		var settings = {
			nr: opentrap,
			set: "fallentyp",
			value: typ,
			apikey: apikey,
		};
		fetchData(URL, settings).then(function (result) {
			doneSave()
		});
		}
	}
	function doneSave(){
		setOpen(false);
		setTableset(false)
		setGroupsFetched(false)
		setGroupsNr(false)
		setGroupsName(false)
		setTable([])
		if(hash == "#/add"){
			window.location.href= '/?app#/dashboard';
		}
		else{
			setVisibleAlert(true)
			setSuccessMsg("Fallenmelder wurden aktualisiert")
			setTimeout(() => {
				setVisibleAlert(false)
			}, 3000);
		}
	}
	const push_popup = async (nr, name, pic, lat, lon, typ, revier,groupid, apikey1) => {
		var dbposition = [lat, lon];
		if(apikey1 === "111"){
			setDisable(true)
		}
		else{
			setDisable(false)
		}
		setName(name);
		setTrap(nr);
		setPics(pic);
		setPosition(dbposition);
		setFallentyp(typ);
		if(revier){
			setGroupsName(revier);
			setGroupsNr(groupid);
		}
		else{
			setGroupsName("Kein Revier");
			setGroupsNr(false);
		}
		
		

		setOpen((o) => !o);
	}
	function setpopup(pop) {
		var subtitle = "Seriennummer: " + opentrap
		var hidden = "none";
		if(settingdisable == true){
			hidden = "hidden";
		}				
		//return "check popup: " + pop + opentrap
		return (
			<CardBody>
			<div className="d-flex align-items-center">
				<div style={{width:"100%"}}>
				<a class='fa fa-times' style={{fontSize:"50px",float: "right"}} onClick={() => {	setOpen(false);		
																									if(hash == "#/add"){
																										window.location.href= '/?app#/dashboard';
																										}}}></a>
				<CardTitle style={{fontSize:"larger"}}>Einstellungen Fallenmelder</CardTitle>
				<CardSubtitle style={{visibility:hidden}}>{subtitle}</CardSubtitle>
				</div>
				</div>
				<div style={{marginTop:"10px"}}>
					<Label for="name">Name</Label>
					<Input id="name" disabled={settingdisable} style={{marginBottom:"8px",fontSize:"20px"}} placeholder={name} onChange={(e) => setName(e.target.value)}></Input>
					<InputGroup  style={{marginBottom:"8px"}}>
						<Input disabled={settingdisable} onChange={(e) => {setGroupsName(e.target.value);setGroupsNr(1)}} placeholder={groups_name} style={{fontSize:"20px"}}></Input>
						<InputGroupButtonDropdown addonType="append" isOpen={dropdownOpen} toggle={() => toggleDropDown()}>
							<DropdownToggle disabled={settingdisable} caret>
								Revier
							</DropdownToggle>
							<DropdownMenu onClick={(e) => {setGroupsNr(e.target.value);setGroupsName(e.target.name)}}>
								{options_reactstrap_Group()}
								<DropdownItem value="Kein Revier" name="Kein Revier">Kein Revier</DropdownItem>
							</DropdownMenu>
						</InputGroupButtonDropdown>
					</InputGroup>
					<InputGroup style={{marginBottom:"8px"}}>
						<Input disabled={settingdisable}  onChange={(e) => setFallentyp(e.target.value)} placeholder={typ} style={{fontSize:"20px"}}></Input>
						<InputGroupButtonDropdown addonType="append" isOpen={dropdownOpen2} toggle={() => toggleDropDown2()}>
							<DropdownToggle disabled={settingdisable} caret>
								Fallentyp
							</DropdownToggle>
							<DropdownMenu onClick={(e) => {setFallentyp(e.target.textContent)}}>
								{options_Typ()}
							</DropdownMenu>
						</InputGroupButtonDropdown>
					</InputGroup>
				</div>
				<div>
					{showmap()}
				</div>

				<div  style={{marginTop:"13px",visibility:hidden}}>
					<a class='fa fa-trash-alt' style={{fontSize:"50px",float: "left",color:"#f71313"}} onClick={() => 	confirmAlert({
																															message: 'Möchtest du diesen Fallenmelder aus deiner Liste löschen?',
																															buttons: [
																															{
																																label: 'Nein'
																															},
																															{
																																label: 'Ja',
																																onClick: () => deleteFM()
																															}
																															]
																														})}></a>
					<a class='fa fa-save' style={{fontSize:"50px",float: "right"}} onClick={() => saveSetting()}></a>
					<center><a class='fa fa-map-marker-alt' style={{fontSize:"50px"}} onClick={() => setgetlocation(opentrap)}></a></center>
				</div>
			</CardBody>
		);
		
	}
	function options_reactstrap_Group(){
		var Data = groups_result,
		MakeItem = function (X) {
			if(X.userid === X.created_from){
				return <DropdownItem value={X.groupid} name={X.group_name}>{X.group_name + " - " + X.groupid}</DropdownItem>;
			}
		};

		return Data.map(MakeItem);

	}
	function options_Group() {
		var Data = groups_result,
		MakeItem = function (X) {
			if(X.userid === X.created_from){
				if(hash.substring(hash.indexOf('=')+1) == X.groupid || X.groupid === groups_nr){
					return <option value={X.groupid} selected>{X.group_name + " - " + X.groupid}</option>;
				}
				else{
					return <option value={X.groupid}>{X.group_name + " - " + X.groupid}</option>;
				}
			}
		};

		return Data.map(MakeItem);
	}
	function options_Typ() {
		var Data = typoptions,
		MakeItem = function (X) {
			return <DropdownItem>{X}</DropdownItem>;
		};

		return Data.map(MakeItem);
	}
	const rowEvents = {
		onClick: (e, row, rowIndex) => {
		push_popup(row.id[0], row.id[1] , "dbpic", row.settings[1],row.settings[2], row.settings[3],row.settings[0],row.settings[5],row.settings[4])
		},

	}
	function deleteFM (){
		var deleteURL = "/api/deletefm";

		var settings = {
			nr: opentrap,
			apikey: apikey
		};
		fetchData(deleteURL, settings).then(function (result) {
			setOpen(false);
			setTableset(false)
			setTable([])
			setGroupsFetched(false)
			setGroupsNr(false)
			setGroupsName(false)
			setVisibleAlert2(true)
			setErrorMsg("Fallenmelder " + opentrap + " - " + name+ " wurden gelöscht!")
			setTimeout(() => {
				setVisibleAlert2(false)
			  }, 10000);
		})

	}
	function showmap(){
		if (position[1] === null) { 
			return}
		var h = window.innerHeight;
		return(					
		<LeafletMap
			style={{ height: 0.5 * h, marginBottom: "5px" }}
			center={position}
			zoom={18}
			maxZoom={30}
			attributionControl={true}
			zoomControl={true}
			doubleClickZoom={true}
			scrollWheelZoom={true}
			dragging={true}
			animate={true}
			easeLinearity={0.35}
			isOpen={false}
			>
				<TileLayer url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" />
				<Marker position={position} icon={Icon}></Marker>
			</LeafletMap>)
	}
	if(isOpen === false){
		return (<div></div>)
	}
	if(tabledata.length == 0 && tableset !== true){
		getidb().then(function (result) {setResults(result)}).then(function(){		
																				setVisibleAlert(true)
																				setSuccessMsg('Mit dem neuen Update der App ist es jetzt möglich deine Fallenmelder in "Reviere" mit anderen Jägern zusammen zu nutzen. Bitte beachte, dass deine bestehenden Reviere zurückgesetzt wurden und du diese neu anlegen musst um deine Mitjäger einladen zu können.')
																	
																			})

		if(addfm){
			setTrap(addfm)
			setGroupsName("Kein Revier")
			setGroupsNr(0)
			setName("Name")
			setFallentyp("Fallentyp")
			setOpen((o) => !o);
		}

		}
	else if (!updateinterval && tableset && navigator.onLine){
		setInterval(() => {tableupdate()}, 2000)
		setUpdateInterval(true)
	}
	var URL = "/api/groups";
	if(!groupsset && apikey){
		fetchData(URL,{apikey: apikey}).then(function(result){set_Option_Group_Results(result);setGroupsFetched(true)})
	}
	return (
		/*--------------------------------------------------------------------------------*/
		/* Used In Dashboard-4 [General]                                                  */
		/*--------------------------------------------------------------------------------*/

		<Card>

		<Popup open={open} closeOnDocumentClick onClose={() => {	setOpen(false);		
																	if(hash == "#/add"){
																						window.location.href= '/?app#/dashboard';
																						}}}> 
			<div style={{zIndex:11,maxHeight: "100vh",overflowY: "auto"}} className="button-group">{setpopup(openpopup)}</div>
			<link href="…" rel="stylesheet" type="text/css" data-autoreload="false"></link>
		</Popup>
		
		<Alert isOpen={visibleAlert} variant="success" dismissible transitionAppearTimeout={150} transitionLeaveTimeout={150} transitionEnterTimeout={150} style={{ height:"auto",position: "relative"}} >
			<a class='fa fa-times' style={{fontSize:"20px",float: "right"}} onClick={() => setVisibleAlert(false)}></a>
			<i class="fas fa-exclamation-triangle" style={{fontSize:"20px",float: "left",marginRight: "1%"}}></i>
			<div>{SuccessMsg}</div>
		</Alert>
		<Alert isOpen={visibleAlert2} color="danger" dismissible transitionAppearTimeout={150} transitionLeaveTimeout={150} transitionEnterTimeout={150} >
			<a class='fa fa-times' style={{fontSize:"20px",float: "right",}} onClick={() => setVisibleAlert2(false)}></a>
			<i class="fas fa-exclamation-triangle" style={{fontSize:"20px",float: "left",marginRight: "1%"}}></i> {ErrorMsg}
		</Alert>
		

		<CardBody>
			<div className="d-flex align-items-center">
			<div>
				<CardTitle>SAFETRAP Fallenmelder</CardTitle>
				<CardSubtitle>Überblick Tabelle</CardSubtitle>
			</div>
			<div className="ml-auto d-flex no-block align-items-center">
				<div className="dl">
				<Input id="reviere" type="select" className="custom-select" onChange={(e) => search(e.target.value)}>
					<option value="ALLE">ALLE</option>
					<option value={apikey}>Eigene</option>
					<option value="Achtung">ACHTUNG</option>
					{options_Group()}
				</Input>
				</div>
			</div>
			</div>

			<BootstrapTable
			bootstrap4
			wrapperClasses="table-responsive"
			striped
			hover
			condensed
			search
			style={{tableLayout:"auto"}}
			columns={ columns }
			data={ tabledata }
			keyField='id'
			bordered={ false }
			noDataIndication="keine Fallenmelder gefunden"
			filter={ filterFactory() }
			rowEvents={ rowEvents }
			defaultSorted={defaultSortedBy}
			>
			</BootstrapTable>
		</CardBody>
		</Card>
	);
};

export default Projects;
