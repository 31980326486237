import React, { useState } from "react";
import ScrollToTop from "react-scroll-up";
import { Button, Card, CardBody, CardTitle, Row, Col } from "reactstrap";
import "../../assets/scss/popupstyles.css";
import "../../assets/scss/style.css";
import fbicon from "../../assets/images/icons/fb_logo.png";
import teleicon from "../../assets/images/icons/telegram_logo.png";

import androidinstall1 from "../../assets/images/icons/android_install1.jpg";
import androidinstall2 from "../../assets/images/icons/android_install2.jpg";
import androidinstall3 from "../../assets/images/icons/android_install3.jpg";
import iosinstall from "../../assets/images/icons/ios_install.jpg";
import windowsinstall from "../../assets/images/icons/install_win2.png";
import melderpic from "../../assets/images/icons/melder.jpg";
import labelpic from "../../assets/images/icons/label.PNG";
import pcbpic from "../../assets/images/icons/pcb.jpg";
import meldungenpic from "../../assets/images/icons/meldungen.PNG";
import settingpic from "../../assets/images/icons/setting.jpg";
import starttelegram from "../../assets/images/icons/starttelegram.jpg";
import infotelegram from "../../assets/images/icons/infotelegram.jpg";
import getinfo from "../../assets/images/icons/getinfo.jpg";

import fbpinpic from "../../assets/images/icons/pin.jpg";
import fbsetup from "../../assets/images/icons/setupfb.jpg";
import fbdone from "../../assets/images/icons/donefb.jpg";

import copy from "copy-to-clipboard";
import alert from "alert";
var scrollToElement = require("scroll-to-element");

const Setting = () => {
  const [fbpin, set_fbpin] = useState("");

  function copypin() {
    /* Select the text field */
    copy(fbpin);

    /* Alert the copied text */
    alert("Copied the text: " + fbpin);
  }
  function scroll(whereto) {
    scrollToElement(whereto, {
      offset: -60,
    });
  }

  //window.location.reload(false);

  return (
    <div>
      <ScrollToTop showUnder={160} style={{ zIndex: "100" }}>
        <i class="mdi mdi-chevron-up" style={{ fontSize: "40px" }}></i>
      </ScrollToTop>
      <Row>
        <Col xs="12" md="12">
          {/* --------------------------------------------------------------------------------*/}
          {/* Card-5*/}
          {/* --------------------------------------------------------------------------------*/}
          <Card>
            <CardBody className="">
              <CardTitle>
                <i className="mdi mdi-toggle-switch mr-2"> </i>
                Inhaltsverzeichnis
              </CardTitle>
              <Button className="btn" color="primary" size="lg" block onClick={() => scroll("#los")}>
                1. Los Geht’s!
              </Button>
              <Button className="btn" color="primary" size="lg" block onClick={() => scroll("#liefer")}>
                2. Lieferumfang
              </Button>
              <Button className="btn" color="primary" size="lg" block onClick={() => scroll("#funktion")}>
                3. Funktion
              </Button>
              <Button className="btn" color="primary" size="lg" block onClick={() => scroll("#fallenmelder")}>
                4. Fallenmelder Nutzen und Wartung
              </Button>
              <Button className="btn" color="primary" size="lg" block onClick={() => scroll("#APP")}>
                5. APP
              </Button>
              <Button className="btn" color="primary" size="lg" block onClick={() => scroll("#telegram")}>
                6. Telegram Bot
              </Button>
              <Button className="btn" color="primary" size="lg" block onClick={() => scroll("#facebook")}>
                7. Facebook Bot
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="12">
          {/* --------------------------------------------------------------------------------*/}
          {/* Card-5*/}
          {/* --------------------------------------------------------------------------------*/}
          <Card id="los">
            <CardBody className="">
              <CardTitle>
                <i className="mdi mdi-toggle-switch mr-2"> </i>
                Los Geht's!
              </CardTitle>
              <div className="button-group">
                <div style={{ margin: "15px", padding: "15px", fontSize: "18px" }}>
                  <p className="mb-0">
                    SAFETRAP wünscht dir viel Erfolg und Waidmannsheil bei der Jagd. Der SAFETRAP Fallenmelder ist die kostengünstige und nachhaltige
                    Alternative für eine sichere und schonende Fallenjagd. Unsere Produkte werden in Deutschland entwickelt, hergestellt und ressourcenbewusst
                    produziert. <br />
                    Der SAFETRAP Fallenmelder wird vollgeladen und mit einer aktivierten SIM Karte ausgeliefert und ist damit direkt einsatzbereit. <br />
                    <br />
                    Wir würden uns sehr freuen, wenn du uns deine Meinung und Verbesserungsvorschläge über{" "}
                    <a href="mailto:service@safetrap.de?subject=Meinung%20/ Verbesserungsvorschlag">service@safetrap.de</a> zukommen lässt. Mit dem SAFETRAP
                    Fallenmelder wollen wir die Fangjagd zugänglicher und günstiger gestalten und wir versuchen unser Produkt immer weiter zu entwickeln, damit
                    wir dir den Jagdalltag erleichtern können.
                  </p>
                </div>
              </div>
              <div style={{ fontSize: "18px", width: "100%", textAlign: "center" }}>
                <i>
                  <img
                    src={melderpic}
                    alt="homepage"
                    style={{ maxWidth: "500px", minWidth: "190px", width: "80%", height: "auto", paddingTop: "5px", margin: "20px 20px" }}
                  />
                </i>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="12">
          {/* --------------------------------------------------------------------------------*/}
          {/* Card-5*/}
          {/* --------------------------------------------------------------------------------*/}
          <Card id="liefer">
            <CardBody className="">
              <CardTitle>
                <i className="mdi mdi-toggle-switch mr-2"> </i>
                Lieferumfang
              </CardTitle>
              <div className="button-group">
                <div style={{ margin: "15px", padding: "15px", fontSize: "18px" }}>
                  <p className="mb-0">
                    Im Lieferumfang ist enthalten:
                    <ul>
                      <li>SAFETRAP Fallenmelder mit integriertem und vollgeladenem Akku</li>
                      <li>Eingebaute und aktivierte SIM Karte</li>
                      <li>Abrissmagnet</li>
                      <li>TORX T20 Bit</li>
                    </ul>
                    Der SAFETRAP Fallenmelder wird eingeschaltet ausgeliefert und kann direkt eingesetzt werden.
                  </p>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="12">
          {/* --------------------------------------------------------------------------------*/}
          {/* Card-5*/}
          {/* --------------------------------------------------------------------------------*/}
          <Card id="funktion">
            <CardBody className="">
              <CardTitle>
                <i className="mdi mdi-toggle-switch mr-2"> </i>
                Funktion
              </CardTitle>
              <div className="button-group">
                <div style={{ margin: "15px", padding: "15px", fontSize: "18px" }}>
                  <p className="mb-0">
                    Der SAFETRAP Fallenmelder wird direkt an der Falle angebracht. Über ein Seil oder Draht wird der Abrissmagnet mit dem Auslösemechanismus der
                    Falle verbunden. Der Abrissmagnet wird auf der linken Seite des Fallenmelders angesetzt um diesen zu aktivieren.
                    <br />
                    <br />
                    Der aktive Fallenmelder sendet 2-mal täglich alle 12 Stunden eine Meldung an den SAFETRAP Server in der der Fallenmelder den Akkustand, die
                    Signalstärke und andere Informationen an den Server übergibt. Der Server verarbeitet diese Informationen und sendet bei auslösen einer
                    Falle, geringen Akkustand oder Inaktivität des Melders, bzw. bei der Aktivierung eines Fallenmelders dem Nutzer eine Nachricht per Telegram,
                    App-Alert, E-Mail oder SMS. Der Nutzer kann den Fallenmelder über die SAFETRAP APP Link kontrollieren und den Akkustand, Signalstärke und
                    Zeitpunkt der letzten Meldung einsehen.
                    <br />
                    <br />
                    Der Sendevorgang des Fallenmelders dauert 20 – 60 Sekunden.
                  </p>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="12">
          {/* --------------------------------------------------------------------------------*/}
          {/* Card-5*/}
          {/* --------------------------------------------------------------------------------*/}
          <Card id="fallenmelder">
            <CardBody className="">
              <CardTitle>
                <i className="mdi mdi-toggle-switch mr-2"> </i>
                Fallenmelder Benutzung und Wartung
              </CardTitle>
              <div className="button-group">
                <div style={{ margin: "15px", padding: "15px", fontSize: "18px" }}>
                  <p className="mb-0">
                    <ol>
                      <li>
                        <b>Anbringen</b>
                      </li>
                      <br />
                      Der Fallenmelder kann über die Befestigungen an den Seiten des Fallenmelders an der Falle befestigt werden. Beim Verschrauben ist Vorsicht
                      geboten um die Befestigung nicht abzubrechen. Wir empfehlen den SAFETRAP Fallenmelder mit Hilfe von Kabelbindern an der Falle anzubringen.
                      Der Abrissmagnet sollte mit einem Seil oder Draht, bzw. direkt an dem Auslösemechanismus der Falle angebracht werden. Nachdem du den
                      sicheren Abriss des Magneten vom Fallenmelders getestet hast, platziere den Magneten auf der linken Seite des Fallenmelders. Es sollte bei
                      Aktivierung der Falle ein Ton erklingen und du erhältst nach 20-60 Sekunden eine Benachrichtigung auf deinem Smartphone.
                      <br />
                      <br />
                      <li>
                        <b>Anschalten / Ausschalten</b>
                      </li>
                      <br />
                      Der Fallenmelder wird aktiviert und angeschaltet ausgeliefert und muss insofern er direkt genutzt wird nicht geöffnet werden.
                      <br />
                      Um den Fallenmelder An- bzw. Auszuschalten, öffne zunächst den Fallenmelder mit dem TORX T20 Bit. Nun findest du oben Rechts den AN/AUS
                      Schalter (Markiert mit ON/OFF siehe Bild 5.b.1). Dieser muss nach links geschoben werden um den Fallenmelder auszuschalten,
                      beziehungsweise nach rechts geschoben werden um den Fallenmelder anzuschalten. Beim Schließen des Gehäuses ist zu beachten die Schrauben
                      vorsichtig und nicht zu stark anzuziehen.
                      <br />
                      <br />
                      <li>
                        <b>Laden des Akkus</b>
                      </li>
                      <br />
                      In dem SAFETRAP Fallenmelder befinden sich zwei Akkuzellen. Diese kannst du über ein Micro-USB Kabel mit einem 5V USB Netzteil laden, in
                      dem du das Micro-USB Kabel mit dem USB-Anschluss in der Mitte unten verbindest. Es sollte nun die LED direkt über dem Anschluss
                      erleuchten. Der Lade Vorgang kann mehrere Stunden dauern und ist abgeschlossen sobald die LED erlöscht.
                      <br />
                      Um die Kapazität und Gesundheit der Akkus zu kontrollieren empfiehlt es sich ein externes Ladegerät, wie das LiitoKala Engineer Lii-500 zu
                      nutzen, um die einzelnen Akkuzellen zu testen und zuladen.
                    </ol>
                  </p>
                  <div style={{ fontSize: "18px", width: "100%", textAlign: "center" }}>
                    <i>
                      <img
                        src={pcbpic}
                        alt="homepage"
                        style={{ maxWidth: "500px", minWidth: "190px", width: "80%", height: "auto", paddingTop: "5px", margin: "20px 20px" }}
                      />
                    </i>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xs="12" md="12">
          {/* --------------------------------------------------------------------------------*/}
          {/* Card-5*/}
          {/* --------------------------------------------------------------------------------*/}
          <Card id="APP">
            <CardBody className="">
              <CardTitle>
                <i className="mdi mdi-toggle-switch mr-2"> </i>
                Nutzung der APP
              </CardTitle>
              <div className="button-group">
                <div style={{ margin: "15px", padding: "15px", fontSize: "18px" }}>
                  <p className="mb-0">
                    Bei der SAFETRAP APP handelt es sich um eine Progressive WEB APP (PWA), welche direkt über den Browser deines Smartphones oder PCs
                    heruntergeladen und genutzt werden kann. Erreichen kannst du die APP über diesen Link{" "}
                    <a href="www.safetrap.de/?app">www.safetrap.de/?app</a>
                    <br />
                    Bitte beachte, dass sich die folgende Anleitung zur Installation der APP, sowie Meldungen / Alarmierungen für iPhone und Android Nutzer
                    unterscheiden.
                    <br />
                    <br />
                    <ol>
                      <li>
                        <b>Installation</b>
                      </li>
                      <br />
                      Wenn du die SAFETRAP APP installieren willst, folge zunächst diesem Link (<a href="www.safetrap.de/?app">www.safetrap.de/?app</a>). Nach
                      diesem Schritt wird zwischen Android- und iPhone-Nutzern unterschieden.
                      <br />
                      <br />
                      <p style={{ fontSize: "18px", fontStyle: "italic", textDecorationLine: "underline", marginBottom: "0" }}>
                        ANDROID (SAMSUNG, HUAWEI, etc.)
                      </p>
                      <br />
                      <p style={{ fontSize: "18px", width: "100%" }}>
                        Nach etwa 3-15 Sekunden wird dir unten „SAFETRAP zum Startbildschirm hinzufügen“ angezeigt. Durch einen Klick auf diesen Dialog wirst du
                        zum Installation Prozess weitergeleitet, welchen du durch einen Klick auf „Installieren“ abschließt. Danach kannst du die APP direkt
                        über einen Klick auf das SAFETRAP Logo starten und nutzen.
                      </p>
                      <div style={{ fontSize: "18px", width: "100%", textAlign: "center" }}>
                        <i>
                          <img
                            src={androidinstall1}
                            alt="homepage"
                            style={{ maxWidth: "300px", minWidth: "190px", width: "30%", height: "auto", paddingTop: "5px", margin: "20px 20px" }}
                          />
                        </i>
                        <i>
                          <img
                            src={androidinstall2}
                            alt="homepage"
                            style={{ maxWidth: "300px", minWidth: "190px", width: "30%", height: "auto", paddingTop: "5px", margin: "20px 20px" }}
                          />
                        </i>
                        <i>
                          <img
                            src={androidinstall3}
                            alt="homepage"
                            style={{ maxWidth: "300px", minWidth: "190px", width: "30%", height: "auto", paddingTop: "5px", margin: "20px 20px" }}
                          />
                        </i>
                      </div>
                      <br />
                      <br />
                      <p style={{ fontSize: "18px", fontStyle: "italic", textDecorationLine: "underline", marginBottom: "0" }}>iOS (iPhone, iPad)</p>
                      <br />
                      Sobald du den Link erreichst, wird dir eine Kurzanleitung angezeigt, welche du folgen kannst um die SAFETRAP APP auf deinem APPLE-Gerät zu
                      installieren. Zunächst klickst du auf den Teilen-Symbol unten in der Leiste. Darauf hin musst du in dem Teilen-Menü nach unten scrollen,
                      um durch einen Klick auf das „+“-Symbol mit der Beschreibung „Zum Home-Bildschirm“ die APP zu installieren.
                      <div style={{ fontSize: "18px", width: "100%", textAlign: "center" }}>
                        <i>
                          <img
                            src={iosinstall}
                            alt="homepage"
                            style={{ maxWidth: "300px", minWidth: "190px", width: "30%", height: "auto", paddingTop: "5px", margin: "20px 20px" }}
                          />
                        </i>
                      </div>
                      <br />
                      <br />
                      <p style={{ fontSize: "18px", fontStyle: "italic", textDecorationLine: "underline", marginBottom: "0" }}>Windows & Linux</p>
                      <br />
                      Um die APP auf deinem Windows oder Linux System zu installieren, nutze den Browser deiner Wahl (Chrome, Firefox, Edge, etc.) und öffne den
                      Link. Oben in der Adressleiste ist ein kleines Symbol zusehen. Durch drücken dieses Symbols wirst du gefragt, ob du dies SAFETRAP APP
                      installieren möchtest.
                      <div style={{ fontSize: "18px", width: "100%", textAlign: "center" }}>
                        <i>
                          <img
                            src={windowsinstall}
                            alt="homepage"
                            style={{ maxWidth: "700px", minWidth: "190px", width: "80%", height: "auto", paddingTop: "5px", margin: "20px 20px" }}
                          />
                        </i>
                      </div>
                      <br />
                      <br />
                      <li>
                        <b>Registrieren / Anmelden</b>
                      </li>
                      <br />
                      Um einen neuen Nutzer zu registrieren oder um dich mit deinem bestehenden Nutzer einzuloggen gehe auf diesen Link oder öffne die
                      installierte APP. Es öffnet sich nun ein Anmelde-Formular, in welches du deine E-Mail-Adresse und dein Passwort eintragen kannst, um dich
                      mit deinem bereits registrierten Nutzer anzumelden. Um einen neuen Nutzer anzulegen, klickst du auf das Feld „Noch keinen Account?“,
                      welches dich zu einem Registrierungs-Formular weiterleitet, in welches du deine Daten eintragen kannst um einen neuen Nutzer anzulegen. In
                      Zukunft kannst du dich mit dem hier festgelegten Passwort und deiner E-Mail-Adresse direkt anmelden.
                      <div style={{ fontSize: "18px", width: "100%", textAlign: "center" }}>
                        <i>
                          <img
                            src={labelpic}
                            alt="homepage"
                            style={{ maxWidth: "300px", minWidth: "190px", width: "80%", height: "auto", paddingTop: "5px", margin: "20px 20px" }}
                          />
                        </i>
                      </div>
                      <br />
                      <br />
                      <li>
                        <b>Passwort zurücksetzen</b>
                      </li>
                      <br />
                      Solltest du dein Passwort vergessen haben, nutze im Anmelde-Formular die Option „Passwort vergessen?“. Du wirst nun gebeten deine
                      E-Mail-Adresse einzugeben, welche du genutzt hast um dein Nutzer-Konto anzulegen. Es wird dir daraufhin eine E-Mail zugeschickt, in der
                      sich ein Link befindet mit dem du dein Passwort zurücksenden kannst. Bitte kontrolliere auch deinen SPAM-Ordner solltest du die E-Mail
                      nicht erhalten.
                      <br />
                      <br />
                      <li>
                        <b>Fallenmelder hinzufügen</b>
                      </li>
                      <br />
                      Um einen Fallenmelder deinem Nutzer hinzuzufügen, melde dich zunächst an. Danach kannst du über das Menü die Option „Hinzufügen“ wählen.
                      Das Menü ist bei der Nutzung per Smartphone über einen Klick auf das Menü-Symbol oben rechts zu erreichen. Jetzt kannst du die
                      Seriennummer (SN), welche du auf dem Sticker der sich auf der Rückseite deines Fallenmelders befindet, eintragen und daraufhin deinen
                      Fallenmelder benennen und diesem Eigenschaften und einen Standort hinzufügen. Diese Einstellungen kannst du auch nachträglich eintragen
                      und ändern. Es besteht zudem auch die Möglichkeit über einen Klick auf das QR-Code Symbol unten deine Kamera zu öffnen und den QR-Code auf
                      der Rückseite deines Fallenmelders zu scannen um deinen Fallenmelder hinzuzufügen.
                      <br />
                      <br />
                      <li>
                        <b>Meldungen / Alarmierung</b>
                      </li>
                      <br />
                      Nach dem du dich mit deinem Nutzer in der SAFETRAP APP angemeldet hast, kannst du über das Menü die Option „Einstellungen“ wählen, um
                      deine Nutzer-Einstellungen anzupassen. Ganz unten sind nun vier Möglichkeiten zur Alarmierung verfügbar. Hierbei gibt es für Apple Nutzer
                      eine Einschränkung, da die Nutzung von der Funktion „APP-Alert“ leider von Apple gesperrt wird. Wir empfehlen immer zwei
                      Alarmierungs-Optionen zu wählen, um eine Zustellung der Meldungen zu gewährleisten. Die Option „SMS“ kann erst ab dem 01.05.2021 aktiviert
                      werden und ist zunächst nur für Nutzer innerhalb Deutschlands verfügbar. Die Einrichtung der Funktion „Telegram“ wird in unter Punkt 5.
                      Telegram a. Installation beschrieben. Die Nutzung der Telegram App ermöglicht erweiterte Funktionen und Nutzung der Fallenmelder ohne die
                      SAFETRAP APP.
                      <br />
                      Die aktiven Meldungsoptionen sind hierbei Blau makiert. Deaktivierte Optionen sind Grau.
                      <div style={{ fontSize: "18px", width: "100%", textAlign: "center" }}>
                        <i>
                          <img
                            src={meldungenpic}
                            alt="homepage"
                            style={{ maxWidth: "300px", minWidth: "190px", width: "80%", height: "auto", paddingTop: "5px", margin: "20px 20px" }}
                          />
                        </i>
                      </div>
                      <br />
                      <br />
                      <li>
                        <b>Gruppen</b>
                      </li>
                      <br />
                      Diese Funktion ist in Kürze nutzbar. Über den Menü-Punkt „Gruppen“ können sie zukünftig Gruppen erstellen, um in deinem Revier oder
                      Hegering Fallen zu teilen und die Kontrollen dieser auf mehrere Jäger aufzuteilen. Jeder Jäger in einer Gruppe bekommt alle Meldungen
                      bezüglich der Fallen, welche in dieser Gruppe hinzugefügt wurden.
                      <br />
                      <br />
                      <li>
                        <b>Falleneinstellung</b>
                      </li>
                      <br />
                      In der Tabelle im „Dashboard“ kannst du die Einstellungen deiner Fallenmelder ändern. Ganz rechts in jeder Zeile der Tabelle ist ein
                      Einstellungs-Symbol zu finden, über welches du die Einstellung dieses Fallenmelders ändern kannst. Neben dem Einstellungs-Symbol findest
                      du das Location-Symbol um deiner Falle einen Standort hinzufügen kannst, welches gerade bei der Nutzung in Gruppen sinnvoll sein kann.
                      <div style={{ fontSize: "18px", width: "100%", textAlign: "center" }}>
                        <i>
                          <img
                            src={settingpic}
                            alt="homepage"
                            style={{ maxWidth: "300px", minWidth: "190px", width: "80%", height: "auto", paddingTop: "5px", margin: "20px 20px" }}
                          />
                        </i>
                      </div>
                    </ol>
                  </p>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xs="12" md="12">
          {/* --------------------------------------------------------------------------------*/}
          {/* Card-5*/}
          {/* --------------------------------------------------------------------------------*/}
          <Card id="telegram">
            <CardBody className="">
              <CardTitle>
                <i className="mdi mdi-toggle-switch mr-2"> </i>
                Einrichten des Telegram Bots
              </CardTitle>
              <div className="button-group">
                <div style={{ margin: "15px", padding: "15px", fontSize: "18px" }}>
                  Wir bieten als Alarmierungs-Option die Alarmierung per Telegram an. Telegram ist eine externe Messenger App, sehr ähnlich zu WhatsApp, welche
                  du im App-Store oder im Google Play-Store herunterladen kannst.
                  <br />
                  <br />
                  Über den SAFETRAP Telegram BOT kannst du Alarmierungen bei ausgelösten Fallenmelder erhalten, Fallenmelder Infos abrufen, Fallenmelder
                  hinzufügen und Einstellungen ändern ohne die SAFETRAP APP zu nutzen.
                  <br />
                  <br />
                  <ol>
                    <li>
                      <b>Installation</b>
                    </li>
                    <br />
                    Über folgende Links kannst du die Telegram APP herunterladen.
                    <a href="https://t.me/safetrapBot" target="_blank" rel="noopener noreferrer">
                      <i>
                        <img
                          src={teleicon}
                          alt="homepage"
                          style={{
                            display: "block",
                            maxWidth: "256px",
                            minWidth: "90px",
                            maxHeight: "256px",
                            width: "5%",
                            height: "auto",
                            paddingTop: "5px",
                            margin: "0 auto",
                          }}
                        />
                      </i>
                    </a>
                    <a href="https://t.me/safetrapBot" target="_blank" rel="noopener noreferrer">
                      https://t.me/safetrapBot{" "}
                    </a>
                    <br />
                    <br />
                    Öffne danach die Telegram App und folge den Anweisungen zur Installation.
                    <br />
                    <br />
                    <li>
                      <b>Registrieren</b>
                    </li>
                    <br />
                    Um dich bei dem SAFETRAP Telegram Bot zu registrieren, öffne zunächst die Telegram APP und klicke oben rechts auf die Lupe um in die
                    Suchleiste „SAFETRAP“ einzugeben. Nun kannst du mit einem Klick auf „SAFETRAP BOT“ den Chat öffnen. Nun kannst du auf den Button „Start“
                    oder mit dem senden der Nachricht „/Start“ die Registrierung beginnen. Darauf hin wirst du gebeten deine E-Mail-Adresse anzugeben. Diese
                    kannst du direkt in dem Chat als Nachricht an den SAFETRAP Bot senden. Danach wird dir eine E-Mail zugesendet mit einem Passwort, welches du
                    ebenfalls direkt in den Chat eingeben kannst.
                    <br />
                    <br />
                    <li>
                      <b>Funktionen</b>
                    </li>
                    <br />
                    Der SAFETRAP Telegram BOT bietet eine Vielzahl von Funktionen, welche du unabhängig von der SAFETRAP APP nutzen kannst.
                    <br />
                    Hierzu schickst du dem BOT einfach Befehle und du erhältst darauf hin deine angeforderten Informationen. Befehle starten immer mit einem „/“
                    (Slash bzw. Schrägstrich). Nachfolgend findest du eine Liste mit Befehlen, welche du dem BOT schicken kannst.
                    <br /> Schicke dem SAFETRAP Telegram Bot den Befehl „/info“ und erhalte eine Liste an nutzbaren Befehlen. <br />
                  </ol>
                  <div style={{ fontSize: "18px", width: "100%", textAlign: "center" }}>
                    <i>
                      <img
                        src={starttelegram}
                        alt="homepage"
                        style={{ maxWidth: "300px", minWidth: "190px", width: "30%", height: "auto", paddingTop: "5px", margin: "20px 20px" }}
                      />
                    </i>
                    <i>
                      <img
                        src={infotelegram}
                        alt="homepage"
                        style={{ maxWidth: "300px", minWidth: "190px", width: "30%", height: "auto", paddingTop: "5px", margin: "20px 20px" }}
                      />
                    </i>
                    <i>
                      <img
                        src={getinfo}
                        alt="homepage"
                        style={{ maxWidth: "300px", minWidth: "190px", width: "30%", height: "auto", paddingTop: "5px", margin: "20px 20px" }}
                      />
                    </i>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xs="12" md="12">
          {/* --------------------------------------------------------------------------------*/}
          {/* Card-5*/}
          {/* --------------------------------------------------------------------------------*/}
          <Card id="facebook">
            <CardBody className="">
              <CardTitle>
                <i className="mdi mdi-toggle-switch mr-2"> </i>
                Einrichten des Facebook Bots
              </CardTitle>
              <div className="button-group">
                <div style={{ margin: "15px", padding: "15px", fontSize: "18px" }}>
                  Wir bieten als Alarmierungs-Option die Alarmierung per Facebook Messenger an. Nachdem du dich in der SAFETRAP APP angemeldet hast findest du
                  in deinen Einstellungen einen SAFETRAP Facebook Pin. <br />
                  <br />
                  Um die Benachrichtigung über den Facebook Messenger einzurichten, kopiere deinen SAFETRAP FACEBOOK Pin und schicke diesen direkt per FACEBOOK
                  Messenger an die{" "}
                  <a href="https://m.me/safetrap.fallenmelder" target="_blank" rel="noopener noreferrer">
                    {" "}
                    SAFETRAP
                  </a>{" "}
                  Seite!
                  <a href="https://m.me/safetrap.fallenmelder" target="_blank" rel="noopener noreferrer">
                    <i>
                      <img
                        src={fbicon}
                        alt="homepage"
                        style={{
                          display: "block",
                          maxWidth: "256px",
                          minWidth: "90px",
                          maxHeight: "256px",
                          width: "5%",
                          height: "auto",
                          paddingTop: "5px",
                          margin: "0 auto",
                        }}
                      />
                    </i>
                  </a>
                  <div style={{ fontSize: "18px", width: "100%", textAlign: "center" }}>
                    <i>
                      <img
                        src={fbpinpic}
                        alt="homepage"
                        style={{ maxWidth: "300px", minWidth: "190px", width: "30%", height: "auto", paddingTop: "5px", margin: "20px 20px" }}
                      />
                    </i>
                    <i>
                      <img
                        src={fbsetup}
                        alt="homepage"
                        style={{ maxWidth: "300px", minWidth: "190px", width: "30%", height: "auto", paddingTop: "5px", margin: "20px 20px" }}
                      />
                    </i>
                    <i>
                      <img
                        src={fbdone}
                        alt="homepage"
                        style={{ maxWidth: "300px", minWidth: "190px", width: "30%", height: "auto", paddingTop: "5px", margin: "20px 20px" }}
                      />
                    </i>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Setting;
