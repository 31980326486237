import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import MuiPhoneNumber from "material-ui-phone-number";
import { Component } from "react";
import axios from "axios";

import logodarkicon from "../src/assets/images/logo.svg";
import logodarktext from "../src/assets/images/text.svg";
import { checklogin } from "./checklogin";

import Popup from "reactjs-popup";
import "./assets/scss/popupstyles.css";
import PWAPrompt from "react-ios-pwa-prompt";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <Link color="inherit" href="/impressum.html" target="_blank">
        {"©"} {new Date().getFullYear()} SAFETRAP
      </Link>
    </Typography>
  );
}

const config = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
};

export default class Login extends Component {
  constructor(props) {
    super(props);
    if (window.location.search === "?reset") {
      console.log("checkrest");
      this.state = {
        search: window.location.search,
        hash: window.location.hash.split(";"),
        action: "resetting",
        email: "",
        password: "",
        password2: "",
        name: "",
        telefon: "",
        loginErrors: "",
        display: "none",
        displaypassword: "",
        displayemail: "none",
        displaynone: "",
        login: "",
        register: "Neues Passwort zuweisen",
        displayinfo: "",
        setinfo: "Gebe dein neues Passwort ein!",
        passwordvergessen: "",
        openpopup: false,
      };
    } else {
      this.state = {
        action: "login",
        email: "",
        password: "",
        password2: "",
        name: "",
        telefon: "",
        loginErrors: "",
        display: "none",
        displaypassword: "",
        displayemail: "",
        displaynone: "",
        login: "Noch keinen Account?",
        register: "Anmelden",
        displayinfo: "none",
        setinfo: "E-Mail Adresse mit der du bei SAFETRAP registriert bist.",
        passwordvergessen: "Passwort vergessen?",
        openpopup: false,
      };
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
	if(event.target){
		this.setState({
			[event.target.name]: event.target.value,
		  });
	}
	else{
		this.setState({
			telefon: event,
		  });
	}

  }

  handleSubmit(event) {
    const { email, password, password2, telefon, name } = this.state;

    var URLlogin = "/api/login";
    var URLregister = "/api/create";
    var URLreset = "/api/pw_reset";

    if (this.state.action === "login") {
      axios
        .post(
          URLlogin,
          {
            email: email,
            password: password,
          },
          config
        )
        .then((response) => {
          console.log(response.data[0]);

          if (response.data[0].apikey) {
            checklogin(response.data[0]);
            window.location.reload(true);
          } else {
            this.setState({
              openpopup: true,
              response: response.data,
            });
          }
        })
        .catch((error) => {
          console.log("login error", error);
        });
    } else if (this.state.action === "register") {
      axios
        .post(
          URLregister,
          {
            username: name,
            password: password,
            handy: telefon,
            email: email,
          },
          config
        )
        .then((response) => {
          axios
            .post(
              URLlogin,
              {
                email: email,
                password: password,
              },
              config
            )
            .then((response) => {
              console.log("response.data[0]");
			  console.log(response.status)
              if (response.data[0].apikey) {
                checklogin(response.data[0]);
                window.location.reload(true);
              } else {
                this.setState({
                  openpopup: true,
                  response: response.data,
                });
              }
            })
            .catch((error) => {
              console.log("login error", error);
            });
        })
        .catch((error) => {
			this.setState({
				openpopup: true,
				response: error.response.data,
				});
        });
    } else if (this.state.action === "reset") {
      axios
        .post(
          URLreset,
          {
            email: email,
          },
          config
        )
        .then((response) => {
          console.log(response.data);
          this.setState({
            openpopup: true,
            response: response.data,
          });
        })
        .catch((error) => {
          console.log("login error", error);
        });
    }
    event.preventDefault();
  }

  boxClick = (e) => {
    console.log(e);
    if (e === "reset") {
      this.setState({
        action: "reset",
        login: "Anmelden?",
        display: "none",
        displaypassword: "none",
        displayinfo: "",
        register: "Passwort zurücksetzen",
      });
      console.log("reset password");
      document.getElementById("name").required = false;
      document.getElementById("telefon").required = false;
      document.getElementById("password").required = false;
      document.getElementById("password2").required = false;
    } else if (e === "Noch keinen Account?") {
      this.setState({
        action: "register",
        display: "",
        displaynone: "none",
        displaypassword: "",
        displayinfo: "none",
        login: "Anmelden?",
        register: "Registrieren",
      });
      document.getElementById("name").required = true;
      document.getElementById("telefon").required = true;
      document.getElementById("password2").required = true;
    } else {
      this.setState({
        action: "login",
        display: "none",
        displaynone: "",
        displaypassword: "",
        displayinfo: "none",
        login: "Noch keinen Account?",
        register: "Anmelden",
      });
      document.getElementById("name").required = false;
      document.getElementById("telefon").required = false;
      document.getElementById("password2").required = false;
    }
  };

  setpopup = (e) => {};

  closepopup = (e) => {
    this.setState({
      openpopup: false,
    });
  };

  render() {
    return (
      <Container component="main" maxWidth="xs">
        <Popup open={this.state.openpopup} closeOnDocumentClick onClose={this.closepopup}>
          <Grid container>
            <Grid item xs>
              <div variant="body2" style={{ textAlign: "center", marginTop: "20px", marginBottom: "20px", fontSize: "17px", borderRadius: "15px" }}>
                {this.state.response}
              </div>
            </Grid>
          </Grid>
        </Popup>
        <CssBaseline />
        <div className="headerlogin" style={{ textAlign: "center", padding: "10%" }}>
          <b className="logo-icon">
            <img src={logodarkicon} alt="homepage" className="dark-logo" />
          </b>

          <Typography component="h1" variant="h5">
            <span className="logo-text">
              <img src={logodarktext} alt="homepage" className="dark-logo" />
            </span>
          </Typography>
          <form onSubmit={this.handleSubmit}>
            <Grid container style={{ display: this.state.displayinfo }}>
              <Grid item xs>
                <div variant="body2" style={{ float: "left", marginTop: "20px", fontSize: "17px" }}>
                  {this.state.setinfo}
                </div>
              </Grid>
            </Grid>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="name"
              label="Nutzername"
              type="text"
              id="name"
              value={this.state.name}
              onChange={this.handleChange}
              style={{ display: this.state.display }}
            />

            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              label="E-Mail Adresse"
              name="email"
			  type="email"
              autoComplete="email"
              autoFocus
              value={this.state.email}
              onChange={this.handleChange}
              style={{ display: this.state.displayemail }}
            />


			<MuiPhoneNumber
					style={{ minWidth: "min-content",textAlign:"center" ,marginTop:"2px", display: this.state.display  }}
					defaultCountry={"de"}
					regions={'europe'}
					disableAreaCodes={true}
					variant="outlined"
					fullWidth
					margin="normal"
					type="tel"
					id="telefon"
					value={this.state.telefon.toString()}
					onChange={this.handleChange}
					autoFormat={false}
					/>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Passwort"
              type="password"
              id="password"
              autoComplete="current-password"
              value={this.state.password}
              onChange={this.handleChange}
              style={{ display: this.state.displaypassword }}
            />

            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="password2"
              label="Passwort wiederholen"
              type="password"
              id="password2"
              autoComplete="current-password"
              value={this.state.password2}
              onChange={this.handleChange}
              style={{ display: this.state.display }}
            />

            <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" style={{ display: "none" }} />
            <Button type="submit" fullWidth variant="contained" color="primary">
              {this.state.register}
            </Button>
            <Grid container style={{ marginTop: "15px" }}>
              <Grid item xs>
                <Link href="#email" variant="body2" style={{ float: "left" }} onClick={(e) => this.boxClick("reset")}>
                  {this.state.passwordvergessen}
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2" onClick={(e) => this.boxClick(this.state.login)}>
                  {this.state.login}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
        <PWAPrompt
          copyShareButtonLabel="1) Drücke den 'Share' Button auf der Menüleiste."
          copyAddHomeButtonLabel="2) Drücke 'Zum Home-Bildschirm'."
          copyTitle="APP installieren"
          copyBody="Diese Webseite hat App Funktionalität und kann zum Startbildschirm deines Gerätes hinzugefügt werden."
          promptOnVisit={1}
          timesToShow={3}
          copyClosePrompt="Schließen"
          permanentlyHideOnDismiss={false}
        />
      </Container>
    );
  }
}
