import Starter from "../views/starter/starter.jsx";
// ui components
import MAP from "../views/ui-components/map.jsx";
import Add from "../views/ui-components/add.jsx";
import Instructions from "../views/ui-components/instructions.jsx";
import Setting from "../views/ui-components/setting.jsx";
import Revier from "../views/ui-components/revier.jsx";

var ThemeRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "mdi mdi-home",
    component: Starter,
  },
  {
    path: "/add",
    name: "Hinzufügen",
    icon: "mdi mdi-plus-circle",
    component: Add,
  },
  {
    path: "/map",
    name: "Karte",
    icon: "mdi mdi-google-maps",
    component: MAP,
  },
  {
    path: "/Groups",
    name: "Reviere",
    icon: "mdi mdi-account-multiple-plus",
    component: Revier,
  },

  {
    path: "/help",
    name: "Anleitung",
    icon: "mdi mdi-book-open-page-variant",
    component: Instructions,
  },

  {
    path: "/settings",
    name: "Einstellungen",
    icon: "mdi mdi-image-filter-vintage",
    component: Setting,
  },
  { path: "/", pathTo: "/dashboard", name: "Dashboard", redirect: true },
];
export default ThemeRoutes;
