import React from "react";
import { NavLink } from "react-router-dom";
import { Nav } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { checklogin } from "../../checklogin";
const Sidebar = (props) => {
  const logout = () => {
    console.log("logout");
    checklogin("0");
    window.location.reload(true);
  };
  /*--------------------------------------------------------------------------------*/
  /*To Expand SITE_LOGO With Sidebar-Menu on Hover                                  */
  /*--------------------------------------------------------------------------------*/
  const expandLogo = () => {
    document.getElementById("logobg").classList.toggle("expand-logo");
  };
  /*--------------------------------------------------------------------------------*/
  /*Verifies if routeName is the one active (in browser input)                      */
  /*--------------------------------------------------------------------------------*/

  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "selected" : "";
  };

  return (
    <aside className="left-sidebar" id="sidebarbg" data-sidebarbg="skin6" onMouseEnter={expandLogo.bind(null)} onMouseLeave={expandLogo.bind(null)}>
      <div className="scroll-sidebar">
        <PerfectScrollbar className="sidebar-nav">
          {/*--------------------------------------------------------------------------------*/}
          {/* Sidebar Menus will go here                                                */}
          {/*--------------------------------------------------------------------------------*/}
          <Nav id="sidebarnav">
            {props.routes.map((prop, key) => {
              if (prop.redirect) {
                return null;
              } else {
                return (
                  /*--------------------------------------------------------------------------------*/
                  /* Adding Sidebar Item                                                            */
                  /*--------------------------------------------------------------------------------*/
                  <li className={activeRoute.bind(prop.path) + (prop.pro ? " active active-pro" : "") + " sidebar-item"} key={key}>
                    <NavLink to={prop.path} className="sidebar-link" activeClassName="active">
                      <i className={prop.icon} />
                      <span className="hide-menu">{prop.name}</span>
                    </NavLink>
                  </li>
                );
              }
            })}
          </Nav>
          <div className="nav" style={{ bottom: "0", position: "fixed" }} onClick={() => logout()}>
            <div className="sidebar-item" id="logout" style={{ width: "250px" }}>
              {" "}
              {/*sidebar-item*/}
              <a
                className="sidebar-link"
                aria-current="true"
                id="logoutlink"
                style={{ padding: "12px 15px", display: "flex", cursor: "pointer", whiteSpace: "nowrap", alignItems: "center", lineHeight: "25px" }}
              >
                {" "}
                {/*sidebar-item*/}
                <li>
                  <i
                    className="mdi mdi-logout"
                    style={{ fontStyle: "normal", width: "35px", lineHeight: "25px", fontSize: "20px", display: "inline-block", textAlign: "center" }}
                  />
                  <span className="hide-menu">Abmelden</span>
                </li>
              </a>
            </div>
          </div>
        </PerfectScrollbar>
      </div>
    </aside>
  );
};
export default Sidebar;
