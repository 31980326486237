import React, { useState } from "react";
import QrReader from "react-qr-reader";
import { CardSubtitle, Input, Table, Button, Card, CardBody, CardTitle, Row } from "reactstrap";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import Popup from "reactjs-popup";
import "../../assets/scss/popupstyles.css";
import {Projects} from "../../components/dashboard-components";
import qricon from "../../assets/images/icons/qr_code_3.svg";
import { checklogin } from "../../checklogin";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};
const init = {
  async check() {
    var data = await checklogin("add");
    return data;
  },
};

const Add = () => {
  const [qr_cam_open, set_qr_cam_open] = useState("none");
  const [none_qr_cam_open, set_none_qr_cam_open] = useState("");
  const [trap_id, set_trap_id] = useState("");
  const [added_id, set_added_id] = useState("");
  const [open_popup, set_open_popup] = useState(false);
  const [apikey, set_apikey] = useState("");
  const [api_response, set_api_response] = useState("");
  const [frontend_response, set_frontend_response] = useState("");
  const [tableopen, setTableOpen] = useState(false);

  if(window.location.hash){
	const hash = window.location.hash;
	if(hash.includes("SN=") && trap_id == ""){
	  console.log("adding");
	  add_trap(hash.substring(hash.indexOf('SN=')+3,hash.indexOf('&')))
	  
  }
  }

  function add_trap(e) {
    if (e !== null && e !== "" && e !== added_id) {
	  set_added_id(e);
	  set_trap_id(e);
      var URLadd = "/api/add";
      init.check().then(function (result) {
        set_apikey(result[0].apikey);
        axios
          .post(
            URLadd,
            {
              nr: e,
              apikey: result[0].apikey,
            },
            config
          )
          .then((response) => {
            console.log(response.data);
            set_api_response(response.data);
            if (response.data === "Fallenmelder already registered." || response.data === "Fallenmelder does not exist.") {
				if (response.data === "Fallenmelder already registered.") {
					set_frontend_response("Fallenmelder wurde bereits registriert.");
				  } 
				if (response.data === "Fallenmelder does not exist.") {
					set_frontend_response("Fallenmelder konnte nicht gefunden werden.");
				  } 
              set_open_popup(true);
            } else {
              set_qr_cam_open("none");
              set_none_qr_cam_open("none");
			  setTableOpen(true)
            }
          })
          .catch((error) => {
            console.log("error", error);
            set_open_popup(true);
          });
      });
    }
  }
  function qr_code(e) {
    if (e !== null) {
      if (e.indexOf("SN=") !== -1) {
        const index_pin = e.indexOf("&PIN=");
        const index_sn = e.indexOf("SN=");
        const SN = e.substring(index_sn + 3, index_pin);
        const PIN = e.substring(index_pin + 5);
        add_trap(SN);
      }
    }
  }
  function close_popup() {
    set_open_popup(false);
  }
  function handleKeyPress (event){
	if(event.key === 'Enter'){
	  console.log(trap_id)
	  add_trap(trap_id)
	}
  }
  function setupQR() {
	if(qr_cam_open === ""){
		return (<Card id="QR" style={{ width: "100%"}}>
		<CardBody className="">
		<div className="d-flex align-items-center">
			<div>
			<CardTitle>
				<i className="mdi mdi-arrange-send-backward mr-2"> </i>Qr-Code Scanner
			</CardTitle>
			<CardSubtitle>Scanne jetzt den QR-Code der sich auf dem Fallenmelder befindet.</CardSubtitle>
			</div>
			<a
			onClick={function () {
				set_qr_cam_open("none");
				set_none_qr_cam_open("");
			}}
			className="ml-auto d-flex no-block align-items-center"
			style={{ fontSize: "25px" }}
			>
			&#10006;
			</a>
		</div>
		<div>
			<QrReader delay={300} style={{ width: "100%" }} onScan={(e) => qr_code(e)} />
			<p></p>
		</div>
		</CardBody>
	</Card>)
	}
	else{return(<div></div>)}

  }
  return (
    <div style={{ width: "100%" }}>
      <Popup open={open_popup} closeOnDocumentClick onClose={(e) => close_popup()}>
        <div className="button-group">
          <p className="mb-0" style={{ textAlign: "center", fontSize: "18px" }}>
		  	<p bold className="mb-0" style={{ textAlign: "center" }}>{frontend_response}</p>
			<span italic style={{ textAlign: "center", fontSize: "13px" }}>{api_response}</span>
          </p>
        </div>
      </Popup>
	  <Projects isOpen={tableopen} addfm={trap_id}/>
      <Row>
        <Card style={{ width: "100%", display: none_qr_cam_open }}>
          <CardBody className="">
            <CardTitle>
              <i className="mdi mdi-toggle-switch mr-2"> </i>
              Fallenmelder Hinzufügen
            </CardTitle>
            <div className="button-group">
              <p className="mb-0" style={{ textAlign: "center" }}>
                Trage hier die Seriennummer deines Fallenmelders ein.
              </p>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="nr"
                label="Seriennummer"
                placeholder="Trage hier die Seriennummer deines Fallenmelders ein."
                type="text"
                id="id"
                onChange={(e) => set_trap_id(e.target.value)}
				onKeyPress={(e) => handleKeyPress(e)}
              />

              <Button className="btn" color="primary" size="lg" block onClick={() => add_trap(trap_id)}>
                Diese Falle hinzufügen!
              </Button>
              <p className="mb-0" style={{ textAlign: "center" }}>
                Klicke auf das Icon unten, um einen QR-Code zuscannen!
              </p>
              <i
                onClick={function () {
                  set_qr_cam_open("");
                  set_none_qr_cam_open("none");
                }}
              >
                <img
                  src={qricon}
                  alt="homepage"
                  style={{
                    display: "block",
                    maxWidth: "256px",
                    minWidth: "90px",
                    maxHeight: "256px",
                    width: "10%",
                    height: "auto",
                    paddingTop: "30px",
                    margin: "0 auto",
                  }}
                />
              </i>
            </div>
          </CardBody>
        </Card>
      </Row>
      <Row>
        {setupQR()}
      </Row>
    </div>
  );
};

export default Add;
