import React from "react";
import { Row, Col } from "reactstrap";

import "../../assets/scss/popupstyles.css";
import "../../assets/scss/style.css";

import ListGroups from "../../views/ui-components/list_groups";
import SetGroups from "../../views/ui-components/set_groups";

const Revier = () => {
  return (
    <div>
      <Row>
        <Col sm={12}>
          <SetGroups />
        </Col>
      </Row>
    </div>
  );
};

export default Revier;
