import React , {  useState }from "react";
import "leaflet/dist/leaflet.css";
import { Map as LeafletMap, TileLayer, Marker, Popup } from "react-leaflet";
import { getidb } from "../../components/dashboard-components/projects/api/getidb";
import markericon from "../../assets/images/icons/marker-icon-blue.png";
import pointicon from "../../assets/images/icons/dot1.png";
import L from "leaflet";

const position = [53.6407, 9.24475];
var Icon = L.icon({
  iconUrl: markericon,

  iconSize: [25, 41], // size of the icon
  iconAnchor: [10, 41], // point of the icon which will correspond to marker's location
  shadowAnchor: [4, 62], // the same for the shadow
  popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
});
const init = {
  async getdata() {
    var getdata = await getidb(true);
	console.log("maps")
    return getdata;
  },
};
const markers = [];

var posi_southWest_lat = 0;
var posi_southWest_lon = 0;
var posi_northEast_lat = 0;
var posi_northEast_lon = 0;
var bounds = null;
var count_markers = 0;

const MyPopupMarker = ({ map, position, children }) => (
  <Marker icon={Icon} map={map} position={position}>
    <Popup>
      <span>{children}</span>
    </Popup>
  </Marker>
);
const MyMarkersList = ({ map, markers }) => {
  const items = markers.map(({ key, ...props }) => <MyPopupMarker key={key} map={map} {...props} />);
  return <div style={{ display: "none" }}>{items}</div>;
};
function addmaker(x, name, lat, lon) {
  markers.push({ key: "marker" + x, position: [lat, lon], children: name });
  var minlat = markers.reduce(function (prev, curr) {
    return prev.position[0] < curr.position[0] ? prev : curr;
  });
  var maxlat = markers.reduce(function (prev, curr) {
    return prev.position[0] > curr.position[0] ? prev : curr;
  });
  var minlon = markers.reduce(function (prev, curr) {
    return prev.position[1] < curr.position[1] ? prev : curr;
  });
  var maxlon = markers.reduce(function (prev, curr) {
    return prev.position[1] > curr.position[1] ? prev : curr;
  });

  posi_southWest_lat = minlat.position[0];
  posi_southWest_lon = minlon.position[1];
  posi_northEast_lat = maxlat.position[0];
  posi_northEast_lon = maxlon.position[1];
  var southWest = L.latLng(posi_southWest_lat, posi_southWest_lon),
    northEast = L.latLng(posi_northEast_lat, posi_northEast_lon);
  if (count_markers === 0) {
    bounds = null;
  } else {
    bounds = L.latLngBounds(southWest, northEast);
    position[0] = (posi_northEast_lat - posi_southWest_lat) / 2 + posi_southWest_lat;
    position[1] = (posi_northEast_lon - posi_southWest_lon) / 2 + posi_southWest_lon;
  }
  count_markers = count_markers + 1;
}
init.getdata().then(function (result) {
  if (result) {
    for (var x = 0; x < result.length; x++) {
      if (result[x].lat !== null && result[x].lon !== null) {
        addmaker(x, result[x].name, result[x].lat, result[x].lon);
      }
    }
  }
});



const MAP = () => {
	const [location, setPosition] = useState([53.6407, 9.24475]);
	const getlocation = () =>{
		navigator.geolocation.getCurrentPosition(function (position) {
			const location2 = [position.coords.latitude, position.coords.longitude];
			setPosition(location2);
			console.log(location2)
			})
	}
	var Icon2 = L.icon({
		iconUrl: pointicon,
		iconSize: [25, 25], // size of the icon
		iconAnchor: [15, 10], // point of the icon which will correspond to marker's location
	  });
	if(location[0] == 53.6407){
		getlocation()
	}
	
  return (
    <div style={{ display: "flex", height: "96%", width: "96%", position: "absolute" }}>
      <LeafletMap
        style={{ width: "100%", marginBottom: "5px", flex: "1 1 100%", zIndex: "9" }}
        zoom={10}
        maxZoom={30}
		center={location}
        attributionControl={true}
        zoomControl={true}
        doubleClickZoom={true}
        scrollWheelZoom={true}
        dragging={true}
        animate={true}
        easeLinearity={0.35}
        bounds={bounds}
        boundsOptions={{ padding: [0, 0] }}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MyMarkersList markers={markers} />
		<Marker icon={Icon2} position={location}>   
			<Popup>
				<span>Dein Standort</span>
			</Popup>
		</Marker>
      </LeafletMap>
    </div>
  );
};

export default MAP;
