import React from 'react';
import ReactDOM from 'react-dom';
import indexRoutes from './routes/index.jsx';
import {  Route, Switch } from 'react-router-dom';
import { HashRouter } from 'react-router-dom'

import './assets/scss/style.css';
import SignIn from './SignIn';
import Reset from './Reset';
import { checklogin } from './checklogin';
import Instructions from './views/ui-components/instructions.jsx';
import axios from "axios";

import * as serviceWorker from './serviceWorker';




serviceWorker.register();


const config = {
	headers: {
	  "Content-Type": "application/json",
	},
  };

const init = {
    async check(reset){
        var loggedin = (await checklogin(reset));
        return loggedin;
    },
}

const hash = window.location.hash.split(";");
const search = window.location.search;
const path = window.location.pathname;
console.log(path);


if (path === "/app/add")
{
    console.log("Render ADD");
	init.check().then(function(result){
		if(result === true){
			var link = "/?app#/add" + search; 
			window.location.href= link;
			}
			else{
			  ReactDOM.render(<SignIn />, document.getElementById('root'));  
		  }
	  }
	  );	
}
else if (path === "/anleitung")
{
    console.log("Render Anleitung");

		  ReactDOM.render(
			<HashRouter>
			
			  <Switch>
				{indexRoutes.map((prop, key) => {
				  return <Route path={prop.path} key={key} component={Instructions} />;
				})}
			  </Switch>
			
			</HashRouter>
			,document.getElementById('root'));
	  	
}

else if (search ==="?app")
{
console.log("Render APP");
	init.check().then(function(result){
		if(result === true){
		  ReactDOM.render(
			<HashRouter>
			
			  <Switch>
				{indexRoutes.map((prop, key) => {
				  return <Route path={prop.path} key={key} component={prop.component} />;
				})}
			  </Switch>
			
			</HashRouter>
			,document.getElementById('root'));
			}
			else{
			  ReactDOM.render(<SignIn />, document.getElementById('root'));  
		  }
	  }
	  );
}         
else if (search === "?reset"){
		console.log("Render RESET");		
		ReactDOM.render(<Reset />, document.getElementById('root'));	
}
else if (search === "?invite"){
	var token = window.location.hash.substring(1)
	
	var URLadd = "/api/add_user_to_group";
	axios
	.post(
	  URLadd,
	  {
		token:token
		
	  },
	  config
	)
	.then((response) => {
		var link = "?app#/Groups#msg=" + response.data 
		window.location.href= link;
		console.log(response)})
	
}
else {
	window.location.href= '/main.html';
}

