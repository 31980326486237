import React, { useState } from "react";
import { Button,Alert, ButtonGroup, Card, CardBody, CardTitle, Row, Col, CardSubtitle, Table } from "reactstrap";
import { checklogin } from "../../checklogin";
import { fetchData } from "../../components/dashboard-components/projects/api/fetchData";
import { getidb } from "../../components/dashboard-components/projects/api/getidb";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Popup from "reactjs-popup";
import "../../assets/scss/popupstyles.css";
import "../../assets/scss/style.css";
import fbicon from "../../assets/images/icons/fb_logo.png";
import teleicon from "../../assets/images/icons/telegram_logo.png";
import MuiPhoneNumber from "material-ui-phone-number";

import copy from "copy-to-clipboard";
import alert from "alert";

const init = {
  async check() {
    var data = await checklogin("add");
    return data;
  },
  async getdata() {
    var getdata = await getidb();
	console.log("set")
    return getdata;
  },
};

const Setting = () => {
  const [apikey, set_apikey] = useState("");
  const [stelegram, set_stelegram] = useState(false);
  const [sfb, set_sfb] = useState("");
  const [semail, set_semail] = useState("");
  const [scall, set_scall] = useState("");
  const [fbpin, set_fbpin] = useState("");

  const [cSelected, setCSelected] = useState([]);

  const [name, setName] = useState([]);
  const [nr, setNr] = useState([]);
  const [email, setEmail] = useState([]);
  const [setting, setSetting] = useState([]);
  const [open, setOpen] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [visibleAlert2, setVisibleAlert2] = useState(false);
  const [ErrorMsg, setErrorMsg] = useState(false);
  const [SuccessMsg, setSuccessMsg] = useState(false);
  if (apikey != "") {
    console.log("loaded");
  } else {
    init.check().then(function (result) {
      setNr(result[0].handy);
      setName(result[0].username);
      setEmail(result[0].email);
      setSetting(result[0].setting);
      set_apikey(result[0].apikey);
      set_stelegram(result[0].setting_telegram);
      set_sfb(result[0].setting_facebook);
      set_semail(result[0].setting_email);
      set_fbpin(result[0].fb_pin);
      set_scall(result[0].setting_call);

      console.log("init done");
      console.log(result[0].setting_telegram);
      console.log(apikey);
    });
  }

  const settelegram = (active) => {
    var URL = "/api/setting";
    var settings;
	var value;
	if(stelegram){
		setalert("Benachrichtigung per Telegram ausgeschaltet!")
		set_stelegram(0);
		value=0
	}
	else{
		setalert("Benachrichtigung per Telegram eingeschaltet!")
		set_stelegram(1);
		value=1
	}
    settings = {
        set: "setting_telegram",
        value: value,
        apikey: apikey,
      };
      fetchData(URL, settings).then(function (response) {
        console.log(response[0]);
        checklogin(response[0]);
      });
    
    //checklogin("update")
  };

  const setfb = (active) => {
    var URL = "/api/setting";
    var settings;
	var value;
	if(sfb){
		setalert("Benachrichtigung per Facebook ausgeschaltet!")
		set_sfb(0);
		value=0
	}
	else{
		setalert("Benachrichtigung per Facebook eingeschaltet!")
		set_sfb(1);
		value=1
	}

	settings = {
	set: "setting_facebook",
	value: value,
	apikey: apikey,
	};
	fetchData(URL, settings).then(function (response) {
	console.log(response[0]);
	checklogin(response[0]);
	});
       
  };

  const setemail = (active) => {
    var URL = "/api/setting";
    var settings;	
	var value;
	if(semail){
		setalert("Benachrichtigung per Email ausgeschaltet!")
		set_semail(0);
		value=0
	}
	else{
		setalert("Benachrichtigung per Email eingeschaltet!")
		set_semail(1);
		value=1
	}
	settings = {
	set: "setting_email",
	value: value,
	apikey: apikey,
	};
	fetchData(URL, settings).then(function (response) {
		console.log(response[0]);
		checklogin(response[0]);
	});
    //checklogin("update")
  };

  async function subscribe() {
    var URL = "/api/setting";
    let push;
    if (scall === 0) {
      cSelected.push(4);
      console.log("selceted");
      let sw = await navigator.serviceWorker.ready;
      push = await sw.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: "BPOZrMXaDjXdoVhWE7gkb4ezyMzRBAuQcqkjVN_vtsCNd7Pep4Bmbxnvni6BRnd1Cmb-TzzZd6jvoEFZDhIY6uI",
      });
      push = JSON.stringify(push);
      console.log(push);
      push = JSON.parse(push);
      console.log(push);
      var settings = {
        set: "endpoint",
        value: push.endpoint,
        apikey: apikey,
      };
      fetchData(URL, settings).then(function (response) {
        console.log(response);
        var settings = {
          set: "p256dh",
          value: push.keys.p256dh,
          apikey: apikey,
        };
        fetchData(URL, settings).then(function (response) {
          var settings = {
            set: "auth",
            value: push.keys.auth,
            apikey: apikey,
          };
          fetchData(URL, settings).then(function (response) {
            settings = {
              set: "setting_call",
              value: 1,
              apikey: apikey,
            };
            fetchData(URL, settings).then(function (response) {
              console.log(response[0]);
              checklogin(response[0]);
              set_scall(1);
			  setalert("Benachrichtigung per App-Alert eingeschaltet!")
            });
          });
        });
      });
    } else {
      var settings = {
        set: "endpoint",
        value: "null",
        apikey: apikey,
      };
      fetchData(URL, settings).then(function (response) {
        console.log(response);
        var settings = {
          set: "p256dh",
          value: "null",
          apikey: apikey,
        };
        fetchData(URL, settings).then(function (response) {
          var settings = {
            set: "auth",
            value: "null",
            apikey: apikey,
          };
          fetchData(URL, settings).then(function (response) {
            settings = {
              set: "setting_call",
              value: 1,
              apikey: apikey,
            };
            fetchData(URL, settings).then(function (response) {
              console.log(response[0]);
              checklogin(response[0]);
			  setalert("Benachrichtigung per App-Alert ausgeschaltet!")
              set_scall(0);
            });
          });
        });
      });
    }
    setCSelected([...cSelected]);
  }

  function copypin() {
    /* Select the text field */
    copy(fbpin);

    /* Alert the copied text */
    alert(fbpin + " in die Zwischenablage kopiert!");
  }

  function saveSetting() {
    var URL = "/api/setting";
    if (name !== "" && name !== null) {
      var settings = {
        set: "username",
        value: name,
        apikey: apikey,
      };
      fetchData(URL, settings).then(function (result) {
        if (email !== "" && email !== null) {
          var settings = {
            set: "email",
            value: email,
            apikey: apikey,
          };
          fetchData(URL, settings).then(function (result2) {
            if (nr !== "" && nr !== null) {
              var settings = {
                set: "handy",
                value: nr,
                apikey: apikey,
              };
              fetchData(URL, settings).then(function (result) {
                setOpen(false);
				checklogin(result[0])
              });
            } else {
              setOpen(false);
			  checklogin(result2[0])
            }
          });
        } else if (nr !== "" && nr !== null) {
          var settings = {
            set: "handy",
            value: nr,
            apikey: apikey,
          };
          fetchData(URL, settings).then(function (result) {
            setOpen(false);
			checklogin(result[0])
          });
        } else {
          setOpen(false);
		  checklogin(result[0])
        }
      });
    } else if (email !== "" && email !== null) {
      var settings = {
        set: "email",
        value: email,
        apikey: apikey,
      };
      fetchData(URL, settings).then(function (result2) {
        if (nr !== "" && nr !== null) {
          var settings = {
            set: "handy",
            value: nr,
            apikey: apikey,
          };
          fetchData(URL, settings).then(function (result) {
            setOpen(false);
			checklogin(result[0])
          });
        } else {
          setOpen(false);
		  checklogin(result2[0])
        }
      });
    } else if (nr !== "" && nr !== null) {
      var settings = {
        set: "handy",
        value: nr,
        apikey: apikey,
      };
      fetchData(URL, settings).then(function (result) {
        setOpen(false);
		checklogin(result[0])
      });
    }


	setalert("Änderungen gespeichert!")
	
    //window.location.reload(false);
  }
  function setalert(msg){
	setVisibleAlert(true)
	setSuccessMsg(msg)
	setTimeout(() => {
		setVisibleAlert(false)
	}, 3000);
  }
  return (
    <div>
      {/* --------------------------------------------------------------------------------*/}
      {/* Start Inner Div*/}
      {/* --------------------------------------------------------------------------------*/}
      {/* --------------------------------------------------------------------------------*/}
      {/* Row*/}
      {/* --------------------------------------------------------------------------------*/}
      <Row>
        <Col xs="12" md="12">
		<Alert isOpen={visibleAlert} variant="success" dismissible transitionAppearTimeout={150} transitionLeaveTimeout={150} transitionEnterTimeout={150} style={{ height:"auto",position: "relative"}} >
			<a class='fa fa-times' style={{fontSize:"20px",float: "right"}} onClick={() => setVisibleAlert(false)}></a>
			<i class="fas fa-exclamation-triangle" style={{fontSize:"20px",float: "left",marginRight: "1%"}}></i>
			<div>{SuccessMsg}</div>
			</Alert>
          {/* --------------------------------------------------------------------------------*/}
          {/* Card-5*/}
          {/* --------------------------------------------------------------------------------*/}
          <Card>
            <CardTitle className="bg-light border-bottom p-3 mb-0">
              <i className="mdi mdi-toggle-switch mr-2"> </i>
              Einstellungen
            </CardTitle>

            <CardBody className="">
              <div className="button-group">
			  <p className="mb-0" style={{ textAlign: "center" }}>
                  Benutzername:
                </p>
				<TextField
                          style={{ minWidth: "min-content",textAlign:"center",marginTop:"2px"  }}
                          variant="outlined"
                          margin="normal"
                          fullWidth
						  inputProps={{min: 0, style: { textAlign: 'center' }}}
                          placeholder={name}
						  value={name}
                          name="name"
                          type="text"
                          id="name"
                          onChange={(e) => setName(e.target.value)}
                        />
				<p className="mb-0" style={{ textAlign: "center" }}>
                  Telefonnummer:
                </p>	
					<MuiPhoneNumber
					style={{ minWidth: "min-content",textAlign:"center" ,marginTop:"2px" }}
					defaultCountry={"de"}
					regions={'europe'}
					disableAreaCodes={true}
					variant="outlined"
					inputProps={{min: 0, style: { textAlign: 'center' }}}
					fullWidth
					margin="normal"
					value={nr.toString()}
					type="tel"
					onChange={(e) => setNr(e)}
					autoFormat={false}
					/>
                <p className="mb-0" style={{ textAlign: "center" }}>
                  E-Mail:
                </p>
				<TextField
                          style={{ minWidth: "min-content",textAlign:"center",marginTop:"2px",marginBottom:"15px"}}
                          variant="outlined"
                          margin="normal"
                          fullWidth
						  inputProps={{min: 0, style: { textAlign: 'center' }}}
                          placeholder={email}
						  value={email}
                          name="nr"
                          type="text"
                          id="nr"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                <Button className="btn" color="primary" size="lg" block onClick={() => saveSetting()}>
                  Speichern
                </Button>
              </div>

              <p className="mb-0" style={{ textAlign: "center" }}>
                Meldungen per:
              </p>
              <ButtonGroup>
                <Button color="primary2" onClick={() => settelegram(stelegram)} active={stelegram}>
                  TELEGRAM
                </Button>
                <Button color="primary2" onClick={() => setfb(sfb)} active={sfb}>
                  FACEBOOK
                </Button>
                <Button color="primary2" onClick={() => setemail(semail)} active={semail}>
                  E-Mail
                </Button>
                <Button color="primary2" onClick={() => subscribe()} active={scall}>
                  App-Alert
                </Button>
              </ButtonGroup>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        

        <Col xs="12" md="12">
          {/* --------------------------------------------------------------------------------*/}
          {/* Card-5*/}
          {/* --------------------------------------------------------------------------------*/}
          <Card>
            <CardBody className="">
              <CardTitle>
                <i className="mdi mdi-toggle-switch mr-2"> </i>
                Einrichten der Benachrichtigung
              </CardTitle>
              <div className="button-group">
                <div style={{ margin: "15px", padding: "15px", fontSize: "18px" }}>
                  <p className="mb-0" style={{ textAlign: "center" }}>
                    Klicke auf das Telegram Icon! Lade zunächst die TELEGRAM App herunter und richte über diesen{" "}
                    <a href="https://t.me/safetrapBot" target="_blank" rel="noopener noreferrer">
                      Link
                    </a>{" "}
                    den Telegram SAFETRAP BOT ein.
                  </p>

                  <a href="https://t.me/safetrapBot" target="_blank" rel="noopener noreferrer">
                    <i>
                      <img
                        src={teleicon}
                        alt="homepage"
                        style={{
                          display: "block",
                          maxWidth: "256px",
                          minWidth: "90px",
                          maxHeight: "256px",
                          width: "5%",
                          height: "auto",
                          paddingTop: "5px",
                          margin: "0 auto",
                        }}
                      />
                    </i>
                  </a>
                </div>
                <div style={{ margin: "15px", padding: "15px", fontSize: "18px" }}>
                  <p className="mb-0" style={{ textAlign: "center" }}>
                    Um die Benachrichtigungen über den Facebook Messenger einzurichten, kopiere deine FACEBOOK Pin (in der Blauen Box unten) und schicke diesen
                    direkt per FACEBOOK Messenger an die{" "}
                    <a href="https://m.me/safetrap.fallenmelder" target="_blank" rel="noopener noreferrer">
                      {" "}
                      SAFETRAP
                    </a>{" "}
                    Seite!
                  </p>
                  <a href="https://m.me/safetrap.fallenmelder" target="_blank" rel="noopener noreferrer">
                    <i>
                      <img
                        src={fbicon}
                        alt="homepage"
                        style={{
                          display: "block",
                          maxWidth: "256px",
                          minWidth: "90px",
                          maxHeight: "256px",
                          width: "5%",
                          height: "auto",
                          paddingTop: "5px",
                          margin: "0 auto",
                        }}
                      />
                    </i>
                  </a>
                  <Button className="btn" color="primary" size="lg" block style={{ width: "50%", margin: "20px 25%" }} onClick={() => copypin()}>
                    {fbpin}
                  </Button>
                </div>
                <div style={{ margin: "15px", padding: "15px", fontSize: "18px" }}>
                  <p className="mb-0" style={{ textAlign: "center" }}>
                    Die Option App-Alert ist nur nutzbar für Android und Windows. Zudem befindet sich diese Option noch in der Testphase und wir empfehlen zu
                    der Option "App-Alert" eine weitere zu wählen. Apple nutzer müssen eine der anderen Optionen wählen.{" "}
                  </p>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* --------------------------------------------------------------------------------*/}
      {/* Row*/}
      {/* --------------------------------------------------------------------------------*/}

      {/* --------------------------------------------------------------------------------*/}
      {/* End Inner Div*/}
      {/* --------------------------------------------------------------------------------*/}
    </div>
  );
};

export default Setting;
