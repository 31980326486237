import { openDB } from 'idb';
import { fetchData } from './fetchData';

export const getidb = async () => {
    
    const dbPromise = openDB('Fallenmelder', 1, {
        upgrade(db) {
            if(!db.objectStoreNames.contains('user')) {
                var userOS = db.createObjectStore('user')
                userOS.createIndex('username','username',{unique: true});
            }
            if(!db.objectStoreNames.contains('melder')) {
                var melderOS = db.createObjectStore('melder')
                melderOS.createIndex('id','id',{unique: true});
            }
        },
    });

    const idbmelder = {
        async get(key) {
        return (await dbPromise).get('melder', key);
        },
        async set(key, val) {
        return (await dbPromise).put('melder', val, key);
        },
        async delete(key) {
        return (await dbPromise).delete('melder', key);
        },
        async clear() {
        return (await dbPromise).clear('melder');
        },
        async keys() {
        return (await dbPromise).getAll('melder');
        },
    };

    const idbuser = {
        async get(key) {
        return (await dbPromise).get('user', key);
        },
        async set(key, val) {
        return (await dbPromise).put('user', val, key);
        },
        async delete(key) {
        return (await dbPromise).delete('user', key);
        },
        async clear() {
        return (await dbPromise).clear('user');
        },
        async keys() {
        return (await dbPromise).getAll('user');
        },
    };


    var val = (await idbuser.keys());

    const getput = async (internet) => {
        if(internet && val[0].apikey){
            var URL = "/api/fallenmelder";
            const data = await fetchData(URL,{apikey: val[0].apikey});//
            const datalen = data.length;

            var x = 0
            for(x; x < datalen; x++){
                await idbmelder.set(x,data[x])
            }  

			var nrList = [];
			var output = [];
			data.forEach( function( item ) {
				
				if(nrList.indexOf(item["nr"]) === -1 && item["nr"]){
					nrList.push(item["nr"])
					output.push(item);}
			});
			output.apikey = val[0].apikey;
            return output;
        }
        else{
            const data = await idbmelder.keys();
            return data;
        }

    }


    var internet = navigator.onLine;


    if (val.length !== 0 ){
        return getput(internet);
    }




   



}
