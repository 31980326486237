import React from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <Link color="inherit" href="/impressum.html" target="_blank">
        {"©"} {new Date().getFullYear()} SAFETRAP
      </Link>
    </Typography>
  );
}

const Footer = () => {
  return (
    <footer className="footer text-center">
      <Copyright />
    </footer>
  );
};
export default Footer;
