import { openDB } from 'idb';



export const checklogin = async (data) => {
    

    const dbPromise = openDB('Fallenmelder', 1, {
        upgrade(db) {
            if(!db.objectStoreNames.contains('user')) {
                var userOS = db.createObjectStore('user')
                userOS.createIndex('username','username',{unique: true});
            }
            if(!db.objectStoreNames.contains('melder')) {
                var melderOS = db.createObjectStore('melder')
                melderOS.createIndex('id','id',{unique: true});
            }
        },
    });
    
    const idbuser = {
        async get(key) {
        return (await dbPromise).get('user', key);
        },
        async set(key, val) {
        return (await dbPromise).put('user', val, key);
        },
        async delete(key) {
        return (await dbPromise).delete('user', key);
        },
        async clear() {
        return (await dbPromise).clear('user');
        },
        async keys() {
        return (await dbPromise).getAll('user');
        },
    };

    const idbmelder = {
        async clear() {
        return (await dbPromise).clear('melder');
        },
    };

    const val = (await idbuser.keys()) || 0;
    
    if(data){
        if(data === "0"){
            idbuser.clear();
            idbmelder.clear();
			console.log("idb cleared");
        }
        else{
            if(data === "add"){
                //console.log(val)
                return val;
            }
            else{
				idbuser.set(data.apikey,data);
                console.log('saved login');
            }

    }}
    





    if (val.length !== 0){
        console.log('logged IN');
        //console.log(val);
        return true;
    }
    else{
        console.log('not logged IN');
        
        return false;
    }


    

}
