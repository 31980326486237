import React, { useState } from "react";
import { CardSubtitle, Button, Card, CardBody, CardTitle, Row } from "reactstrap";
import axios from "axios";
import TextField from "@material-ui/core/TextField";

import Popup from "reactjs-popup";
import "../../assets/scss/popupstyles.css";
import ListGroups from "../../views/ui-components/list_groups";
import { checklogin } from "../../checklogin";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};
const init = {
  async check() {
    var data = await checklogin("add");
	console.log("setgroups")
    return data;
  },
};

const SetGroups = () => {
  const [show_settings, set_show_settings] = useState("none");
  const [show_newgroups, set_show_newgroups] = useState("");
  const [group_name, set_group_name] = useState("");
  const [description, set_description] = useState("");
  const [invite, set_invite] = useState("");
  const [group, setGroup] = useState("");
  const [position, set_position] = useState([]);
  const [open_popup, set_open_popup] = useState(false);
  const [apikey, set_apikey] = useState("");
  const [api_response, set_api_response] = useState("");

  function add_group(e) {
    if (e !== null) {
      set_group_name(e);
      console.log(group_name);
      var URLadd = "/api/new_group";

      init.check().then(function (result) {
        set_apikey(result[0].apikey);
        axios
          .post(
            URLadd,
            {
              groupname: group_name,
              description: description,
              apikey: result[0].apikey,
            },
            config
          )
          .then((response) => {
            var URLadd2 = "/api/invite_user_to_group";
            if (invite !== "") {
              axios
                .post(
                  URLadd2,
                  {
                    groupname: group_name,
                    email: invite,
                    apikey: result[0].apikey,
                  },
                  config
                )
                .then((response) => {
                  set_api_response(response.data);
                  set_open_popup(true);
                });
            }

            set_api_response(response.data);
            set_open_popup(true);
          });
      });
      set_show_settings("None");
      set_show_newgroups("");
    }
  }
  function save_group(e) {
    if (e !== null && e !== "") {
      set_group_name(e);
      set_show_settings("");
      set_show_newgroups("none");
    }
  }
  function close_popup() {
    set_open_popup(false);
	set_group_name("")
	var reloads = "?app#/Groups#msg="+ api_response
	window.location.href= reloads
  }
  function abort() {
    set_show_settings("None");
    set_group_name("");
    set_description("");
    set_invite("");
	set_show_newgroups("");
  }
  function handleKeyPress (event){
	if(event.key === 'Enter'){
	  save_group(group_name)
	}
  }
  return (
	<div>
	<Card>
      <Popup open={open_popup} closeOnDocumentClick onClose={(e) => close_popup()}>
        <div className="button-group">
          <p className="mb-0" style={{ textAlign: "center", fontSize: "20px" }}>
            {api_response}
          </p>
        </div>
      </Popup>
          <CardBody className="">
            <CardTitle>
              <i className="mdi mdi-toggle-switch mr-2"> </i>
              Revier bzw. Jagdgemeinschaft erstellen
            </CardTitle>
            <div className="button-group">
              {/* <p className="mb-0" style={{textAlign:"center"}}>Trage hier den Namen des Reviers ein, welches du erstellen oder dem du beitreten möchtest.</p> */}
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="nr"
                label="Name des Reviers"
                placeholder="Name des Reviers"
				value={group_name}
                type="text"
                id="id"
                onChange={(e) => set_group_name(e.target.value)}
				onKeyPress={(e) => handleKeyPress(e)}
              />
              <Button
                className="btn"
                style={{ marginLeft: "0%", marginTop: "10px", display: show_newgroups }}
                color="primary"
                size="lg"
                onClick={() => save_group(group_name)}
              >
                Revier erstellen
              </Button>
              {/* <Button className="btn" style={{float: "right",marginRight: "5%",marginTop: "10px",display: show_newgroups}} color="primary" size="lg" onClick={() => join_group(group_name)}>
									Revier beitreten
								</Button>  */}
            </div>
			
			<div id="Settings" style={{ display: show_settings }}>
            <TextField
              style={{ minWidth: "min-content" }}
              variant="outlined"
              margin="normal"
              fullWidth
			  value={description}
              name="name"
              label="Beschreibung"
              type="text"
              id="Description"
              multiline
              rows={3}
              onChange={(e) => set_description(e.target.value)}
            />

{ /*           <p className="mb-0" style={{ textAlign: "center" }}>
              Trage hier die E-Mail Adressen deiner MitjägerINNEN ein. Bei mehreren Adressen trenne diese mit einem ";" Semicolon
            </p>
            <TextField
              style={{ minWidth: "min-content", float: "left" }}
              variant="outlined"
              margin="normal"
              fullWidth
              name="fallentyp"
			  label="E-Mail Adressen"
              placeholder="peter.jagd@gmail.de;  kurt.Ludwig@online.de;"
              type="text"
              id="Invites"
              onChange={(e) => set_invite(e.target.value)}
            />
							*/}

            <Button className="btn" style={{ float: "right", marginLeft: "5%", marginTop: "10px" }} color="primary" size="lg" onClick={() => add_group(group_name)}>
              Speichern
            </Button>
            <Button className="btn" style={{  marginRight: "5%", marginTop: "10px" }} color="primary" size="lg" onClick={() => abort()}>
              Abbrechen
            </Button>
          </div>
          </CardBody>




	</Card>
	<ListGroups></ListGroups>
	</div>
  );
};

export default SetGroups;
