import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import { Component } from "react";
import axios from "axios";

import logodarkicon from "../src/assets/images/logo.svg";
import logodarktext from "../src/assets/images/text.svg";

import Popup from "reactjs-popup";
import "./assets/scss/popupstyles.css";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <Link color="inherit" href="/impressum.html" target="_blank">
        {"©"} {new Date().getFullYear()} SAFETRAP
      </Link>
    </Typography>
  );
}

const config = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
};

const token = window.location.hash.substring(1);

export default class Reset extends Component {
  constructor(props) {
    super(props);
    console.log("TESTERERER");
    this.state = {
      token: token.substring(1),
      password: "",
      password2: "",
      loginErrors: "",
      register: "Neues Passwort zuweisen",
      displayinfo: "",
      setinfo: "Gebe dein neues Passwort ein!",
      openpopup: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleSubmit(event) {
    const { password, password2 } = this.state;

    var URLresetting = "/api/pw_reset_confirm";

    //check if passwords are ===

    if (password === password2) {
      axios
        .post(
          URLresetting,
          {
            password: password,
            token: token,
          },
          config
        )
        .then((response) => {
          console.log(response);

          if (response.status === 200) {
            //POPUP SUCCESS
            this.setState({
              openpopup: true,
              response: response.data,
              link: "Zurück zum Login!",
            });
          } else {
            this.setState({
              openpopup: true,
              response: response.msg,
              link: "Neuen Link beantragen!",
            });
            //POPUP ERROR
          }
        })
        .catch((error) => {
          console.log(error.response.status);

          if (error.response.status === 401) {
            this.setState({
              openpopup: true,
              response: "Der genutzte Link ist nicht mehr gültig. Bitte beantrage einen neuen Link. ",
              link: "Zurück",
            });
            //POPUP ERROR
          }
        });
    } else {
      this.setState({
        openpopup: true,
        response: "Deine Passwörter stimmen nicht überein!",
        link: "",
      });
    }

    event.preventDefault();
  }

  setpopup = (e) => {};

  closepopup = (e) => {
    this.setState({
      openpopup: false,
    });
  };

  render() {
    return (
      <Container component="main" maxWidth="xs">
        <Popup open={this.state.openpopup} closeOnDocumentClick onClose={this.closepopup}>
          <Grid container>
            <Grid item xs>
              <div variant="body2" style={{ textAlign: "center", marginTop: "20px", marginBottom: "20px", fontSize: "17px", borderRadius: "15px" }}>
                {this.state.response}{" "}
                <Link color="inherit" href="/?app">
                  {this.state.link}
                </Link>
              </div>
            </Grid>
          </Grid>
        </Popup>
        <CssBaseline />
        <div className="headerlogin" style={{ textAlign: "center", padding: "10%" }}>
          <b className="logo-icon">
            <img src={logodarkicon} alt="homepage" className="dark-logo" />
          </b>

          <Typography component="h1" variant="h5">
            <span className="logo-text">
              <img src={logodarktext} alt="homepage" className="dark-logo" />
            </span>
          </Typography>
          <form onSubmit={this.handleSubmit}>
            <Grid container style={{ display: this.state.displayinfo }}>
              <Grid item xs>
                <div variant="body2" style={{ float: "left", marginTop: "20px", fontSize: "17px" }}>
                  {this.state.setinfo}
                </div>
              </Grid>
            </Grid>

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Passwort"
              type="password"
              id="password"
              value={this.state.password}
              onChange={this.handleChange}
            />

            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              required
              name="password2"
              label="Passwort wiederholen"
              type="password"
              id="password2"
              value={this.state.password2}
              onChange={this.handleChange}
            />

            <Button type="submit" fullWidth variant="contained" color="primary">
              Passwort zurücksetzen
            </Button>
            <Grid container style={{ marginTop: "15px" }}>
              <Grid item>
                <Link href="?app" variant="body2">
                  Zurück zum Login!
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    );
  }
}
