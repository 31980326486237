import React, { useState } from "react";
import { getidb } from "../../components/dashboard-components/projects/api/getidb";
import { fetchData } from "../../components/dashboard-components/projects/api/fetchData";
import Popup from "reactjs-popup";
import "../../assets/scss/popupstyles.css";
import "../../assets/scss/style.css";
import {ListGroup, ListGroupItem,Card, CardBody, CardTitle, Alert, Input, Table,InputGroup,InputGroupAddon,InputGroupButtonDropdown,DropdownToggle,DropdownMenu,DropdownItem } from "reactstrap";
import TextField from "@material-ui/core/TextField";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import "../../assets/scss/sliderstyles.css";
import "react-html5-camera-photo/build/css/index.css";

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import filterFactory from 'react-bootstrap-table2-filter';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { checklogin } from "../../checklogin";
import axios from "axios";

const config = {
	headers: {
	  "Content-Type": "application/json",
	},
  };

const init = {
	async check() {
	  var data = await checklogin("add");
	  return data;
	},
  };

  
const ListGroups = () => {
	const [apikey, setApikey] = useState(false);
	const fallenmelder = [];
	const [tableset, setTableset] = useState(false);
	const [tabledata, setTable] = useState([]);

	const [open, setOpen] = useState(false);
	const [openpopup, setOpenPop] = useState(false);
	const [user_id, setUserID] = useState(false);
	const [groupoptions, setGroupOptions] = useState([]);
	const [typoptions, setTypOptions] = useState([]); 
	const [results, setResults] = useState([]); 
	const [groupmember, setGroupMember] = useState([]); 
	const [settingdisable, setDisable] = useState(false);
	const [group_name, set_group_name] = useState("");
	const [group_id, set_group_id] = useState("");
	const [group_function, set_group_function] = useState("");
	const [description, set_description] = useState("");
	const [invite, set_invite] = useState("");
	const [notifications, set_notifications] = useState(false);
	const [hash_msg, set_hash] = useState("");
 
	const [visibleAlert, setVisibleAlert] = useState(false);
	const [visibleAlert2, setVisibleAlert2] = useState(false);
	const [ErrorMsg, setErrorMsg] = useState(false);
	const [SuccessMsg, setSuccessMsg] = useState(false);
	if(!user_id){
		init.check().then(function (result) {setUserID(result[0].userid);console.log(user_id)})
	}
	const hash = window.location.hash;

	if(!SuccessMsg && hash.includes("msg=")){
		setSuccessMsg(decodeURI(hash.substring(hash.indexOf('msg=',2)+4)))
		setVisibleAlert(true)
		setGroupOptions([])
		setOpen(false)
		setTableset(false)
		setTable([])
	}

	function tableadd(result){
		if(result.length !== 0){
			setResults(result)
			for (var x = 0; x < result.length; x++) {
				var user_function= false
				if(result[x].created_from === user_id){
					user_function= true
				}
				else{
					user_function = false
				}
				if (result[x].created_from === result[x].userid && result[x].group_name !== "" && result[x].group_name !== null){
					fallenmelder.push({Gruppenname:[result[x].email,result[x].group_name,result[x].groupid],Beschreibung:result[x].group_description,Funktion:[user_function,result[x].groupid,result[x].notification]});
					groupoptions.push(result[x].group_name);
				}
				
			}
			if(fallenmelder.length !== 0){
				setTable(fallenmelder)
			}
			
		

		}

	}
	function nameformat(cell) {
		return (
			<div class=""><h5 class="mb-0 font-16 font-medium">{cell[1] + " - " + cell[2]}</h5><span>{cell[0]}</span></div>
			);
	}
	function staformat(cell) {
		return (
			<div style={{display:"inline-block",overflow: "hidden",wordWrap: "break-word",textOverflow: "ellipsis",  maxHeight: "7.2em",lineHeight: "1.8em"}}><span>{cell}</span></div>
			);
	}
	function batformat(cell) {
		var role = "Mitglied"
		if(cell[0]){
			role = "Revierleiter"
		}
		else{
			role = "Mitglied"
		}
		return (
			<div class=""><h5 class="mb-0 font-16 font-medium">{role}</h5></div>
			);
	}
	function setformat() {
		return (
		<div><a  class='fa fa-cog'  ></a> </div>   //onClick={() => push_popup("set", row["id"][0], row["id"][1] , "dbpic", cell[1],cell[2], cell[3],cell[0])}
		);
	}
	const columns = [{
		dataField: 'Gruppenname',
		text: 'Reviername',
		formatter: nameformat,
		sort: true
		
	}, {
		dataField: 'Beschreibung',
		text: 'Beschreibung',
		formatter: staformat,
		sort: true ,
		formatExtraData: {1:'fa fa-circle text-orange',0:'fa fa-circle text-success'}
	}, {
		dataField: 'Funktion',
		text: 'Funktion',
		formatter: batformat,
		sort: true
	},{
		dataField: 'settings',
		text: '',
		formatter: setformat
	}]
	function setpopup() {
		var hidden = "none";
		if(settingdisable == true){
			hidden = "hidden";
		}				
		
		return (
			<CardBody>
				<div className="d-flex align-items-center">
					<div style={{width:"100%"}}>
					<a class='fa fa-times' style={{fontSize:"50px",float: "right"}} onClick={() => {setOpen(false);}}></a>
					<CardTitle style={{fontSize:"larger",marginTop: "8px"}}>Einstellungen Revier</CardTitle>
					</div>
				</div>
				<div style={{marginTop:"10px"}}>
					<InputGroup  style={{marginBottom:"8px"}}>
						<TextField inputProps={{ maxLength: 12 }} label="Name des Reviers" style={{ minWidth: "min-content" }} variant="outlined" margin="normal" fullWidth  disabled={settingdisable} onChange={(e) => set_group_name(e.target.value)} value={group_name} style={{fontSize:"20px"}}></TextField>
					</InputGroup>
					<InputGroup style={{marginBottom:"8px"}}>
						<TextField multiline rows={2} rowsMax={4} label="Beschreibung" style={{ minWidth: "min-content" }} variant="outlined" margin="normal" fullWidth disabled={settingdisable}  onChange={(e) => set_description(e.target.value)} value={description} style={{minWidth: "min-content" ,fontSize:"20px"}}></TextField>
					</InputGroup>
					<InputGroup  style={{marginBottom:"8px",visibility:hidden}}>
						<TextField label="Einladung" type="email" style={{ minWidth: "min-content" }} variant="outlined" margin="normal" fullWidth  disabled={settingdisable} onChange={(e) => set_invite(e.target.value)} placeholder="Trage hier die Email Adresse der Person ein, welche du zu dieser Gruppe einladen möchtest." style={{fontSize:"20px"}}></TextField>
					</InputGroup>
					<div style={{marginBottom:"2%",textAlign:"center"}} ><div>Alarmierungen für dieses Revier:</div> 	AUS<Switch onClick={() => save_groupnotification(!notifications)} checked={notifications}  color="primary"/> EIN</div>
				</div>
				<ListGroup style={{overflowY:"auto",height: "calc(25vh)",width:"100%"}}>
						<ListGroupItem disabled>Mitglieder</ListGroupItem>
						{group_members()}
						</ListGroup>
				<div  style={{marginTop:"30px"}}>
					<a class='fa fa-trash-alt' style={{fontSize:"50px",float: "left",color:"#f71313",visibility:hidden}} onClick={() => 	confirmAlert({
																															message: 'Möchtest du dieses Revier wirklich löschen?',
																															buttons: [
																															{
																																label: 'Nein'
																															},
																															{
																																label: 'Ja',
																																onClick: () => deletegroup(group_id)
																															}
																															]
																														})}></a>
					<a class='fa fa-save' style={{fontSize:"50px",float: "right",visibility:hidden}} onClick={() => save_settings()}></a>
					<center><a class='fas fa-list' style={{fontSize:"50px"}} href={"/?app#/dashboard#groupid="+group_id}></a></center>
				</div>
				
			</CardBody>
		);
		
	}
	const push_popup = async (email, name, description, group_function, groupid,groupnotification) => {
		if(group_function){
			setDisable(false)
		}
		else{
			setDisable(true)
		}
		set_group_function(group_function)
		set_group_name(name);
		set_group_id(groupid)
		set_description(description)
		
		var memberid = []
		for (var x = 0; x < results.length; x++) {
			if(results[x].groupid === groupid){
				if(results[x].userid === user_id){
					set_notifications(results[x].notification)
				}
				memberid.push({username:results[x].username,useremail:results[x].email,created_from:results[x].created_from,userid:results[x].userid,groupid:results[x].groupid})	
			}
		}
		setGroupMember(memberid)

		setOpen((o) => !o);
	}
	function exit_popup() {

		setOpen(false)

		set_description("")
		set_group_name("")
		set_invite("")
		set_notifications(true)
	}
	const rowEvents = {
		
		onClick: (e, row, rowIndex) => {
			console.log(row.Funktion[2])
		push_popup(row.Gruppenname[0], row.Gruppenname[1] , row.Beschreibung,row.Funktion[0],row.Funktion[1],row.Funktion[2])
		},

	}
	const defaultSortedBy = [{
		dataField: "Gruppenname",
		order: "asc"  // or desc
	}]
	function group_members() {

		var Data = groupmember,
		MakeItem = function (X) {
			var colour = "white"
			var hidden = "none";
			if(X.created_from === X.userid){
				colour = '#b7f5bb'
				hidden = "hidden";
			}
			
			if(!group_function && X.userid !== user_id){
				hidden = "hidden";
			}	
			return <ListGroupItem style={{backgroundColor:colour}}><a href={"mailto:" + X.useremail + "?subject=SAFETRAP Revier: " + group_name}>{X.username}</a><a class='fa fa-trash-alt' style={{fontSize:"20px",float: "right",visibility:hidden}} onClick={() => 	confirmAlert({
				message: "Möchtest du " + X.username + " aus dieser Gruppe entfernen?",
				buttons: [
				{
					label: 'Nein'
				},
				{
					label: 'Ja',
					onClick: () => kick_member(X.userid,X.groupid)}]})}></a></ListGroupItem>;
		};
		return Data.map(MakeItem);
	}
	function save_settings() {

		
		if(group_name !== ""){
			var URLadd = "/api/settinggroup";
			axios
			.post(
			  URLadd,
			  {
				set:"group_name",
				groupid: group_id,
				userid: user_id,
				value: group_name,
				
			  },
			  config
			)
			.then((response) => {
				
				if(description !== ""){
					var URLadd = "/api/settinggroup";
					axios
					.post(
					  URLadd,
					  {
						set:"group_description",
						groupid: group_id,
						userid: user_id,
						value: description,
						
					  },
					  config
					)
					.then((response) => {
						
						if(invite !== ""){
							var URLadd = "/api/invite_user_to_group";
							axios
							.post(
							  URLadd,
							  {
								groupid: group_id,
								userid: user_id,
								email: invite,
								
							  },
							  config
							)
							.then((response) => {
								setSuccessMsg("Änderungen gespeichert! " + response.data)
								setGroupOptions([])
								setVisibleAlert(true)
								setOpen(false)
								setTableset(false)
								setTable([])
					
							
							});		
						}
						else{
							setSuccessMsg("Änderungen gespeichert")
							setGroupOptions([])
							setVisibleAlert(true)
							setOpen(false)
							setTableset(false)
							setTable([])
						}


					
					});
				}
				else if(invite !== ""){
					var URLadd = "/api/invite_user_to_group";
					axios
					.post(
					  URLadd,
					  {
						groupid: group_id,
						userid: user_id,
						email: invite,
						
					  },
					  config
					)
					.then((response) => {
						
						setSuccessMsg("Änderungen gespeichert! " + response.data)
						setGroupOptions([])
						setVisibleAlert(true)
						setOpen(false)
						setTableset(false)
						setTable([])
						
			
					
					});		
				}
				else{
					setSuccessMsg("Änderungen gespeichert")
					setGroupOptions([])
					setVisibleAlert(true)
					setOpen(false)
					setTableset(false)
					setTable([])
				}
			
			});
		}
		else if(description !== ""){
			var URLadd = "/api/settinggroup";
			axios
			.post(
			  URLadd,
			  {
				set:"group_description",
				groupid: group_id,
				userid: user_id,
				value: description,
				
			  },
			  config
			)
			.then((response) => {
				
				if(invite !== ""){
					var URLadd = "/api/invite_user_to_group";
					axios
					.post(
					  URLadd,
					  {
						groupid: group_id,
						userid: user_id,
						email: invite,
						
					  },
					  config
					)
					.then((response) => {
						
						setSuccessMsg("Änderungen gespeichert! " + response.data)
						setGroupOptions([])
						setVisibleAlert(true)
						setOpen(false)
						setTableset(false)
						setTable([])
						
			
					
					});		
				}
				else{
					setSuccessMsg("Änderungen gespeichert")
					setGroupOptions([])
					setVisibleAlert(true)
					setOpen(false)
					setTableset(false)
					setTable([])
				}

			
			});
		}
		else if(invite !== ""){
			var URLadd = "/api/invite_user_to_group";
			axios
			.post(
			  URLadd,
			  {
				groupid: group_id,
				userid: user_id,
				email: invite,
				
			  },
			  config
			)
			.then((response) => {
				
				setSuccessMsg(response)
				setGroupOptions([])
				setVisibleAlert(true)
				setOpen(false)
				setTableset(false)
				setTable([])
				
	
			
			});		

		}
		else{
			setSuccessMsg("Keine Änderung")
			setGroupOptions([])
			setVisibleAlert(true)
			setOpen(false)
			setTableset(false)
			setTable([])
		}
		
		
		set_description("")
		set_group_name("")
		set_invite("")
		set_notifications(true)
	}
	function save_groupnotification(noti){
		var URLadd = "/api/settinggroup";
		axios
		.post(
			URLadd,
			{
			set:"group_notification",
			groupid: group_id,
			userid: user_id,
			value: noti,
			
			},
			config
		)
		.then((response) => { 
			setTableset(false)
			setTable([])
			setGroupOptions([])
			if(response.data.includes("alleine")){
				confirmAlert({
					message: response.data,
					})
				
			}
			else{
				set_notifications(noti)
			}

			
	

		})

	}
	function kick_member(memberid,groupid) {
		var URLadd = "/api/settinggroup";
		axios
		.post(
		  URLadd,
		  {
			set:"kickmember",
			groupid: groupid,
			userid: user_id,
			value: memberid,
			
		  },
		  config
		)
		.then((response) => {
			
			setErrorMsg(response.data)
			setGroupOptions([])
			setVisibleAlert2(true)
			setOpen(false)
			setTableset(false)
			setTable([])
			

		
		});
	}
	function deletegroup(groupid) {
		console.log("Delete group: " + groupid)
		var URLadd = "/api/deletegroup";
		axios
		.post(
		  URLadd,
		  {
			groupid: groupid,
			userid: user_id,
			
		  },
		  config
		)
		.then((response) => {
			
			setErrorMsg(response.data)
			setGroupOptions([])
			setVisibleAlert2(true)
			setOpen(false)
			setTableset(false)
			setTable([])
		
		});
	}
	if(tabledata.length == 0 && tableset !== true){
		getidb("groups").then(function (result) {
			setApikey(result.apikey)
			var URL = "/api/groups";
			console.log("get Groups")
			setTableset(true)
            fetchData(URL,{apikey: apikey}).then(function(result){tableadd(result)})
		})
		}
  return (
    <Card>
      <Popup modal nested open={open} closeOnDocumentClick onClose={(e) => exit_popup()}>
        <div className="button-group" style={{zIndex:11,maxHeight: "100vh",overflowY: "auto"}}>{setpopup(openpopup)}</div>
      </Popup>
		<Alert isOpen={visibleAlert} variant="success" dismissible transitionAppearTimeout={150} transitionLeaveTimeout={150} transitionEnterTimeout={150} >
			<a class='fa fa-times' style={{fontSize:"20px",float: "right"}} onClick={() => setVisibleAlert(false)}></a>
			{SuccessMsg}
		</Alert>
		<Alert isOpen={visibleAlert2} color="danger" dismissible transitionAppearTimeout={150} transitionLeaveTimeout={150} transitionEnterTimeout={150} >
			<a class='fa fa-times' style={{fontSize:"20px",float: "right"}} onClick={() => setVisibleAlert2(false)}></a>
			{ErrorMsg}
		</Alert>
		
      <CardBody>
        <div className="d-flex align-items-center">
          <div>
            <CardTitle>Liste deiner Reviere und Jagdgemeinschaften</CardTitle>
          </div>
        </div>

		<BootstrapTable
			bootstrap4
			wrapperClasses="table-responsive"
			striped
			hover
			condensed
			search
			style={{tableLayout:"auto"}}
			columns={ columns }
			data={ tabledata }
			keyField='id'
			bordered={ false }
			noDataIndication="keine Reviere oder Jagdgemeinschaften gefunden"
			filter={ filterFactory() }
			rowEvents={ rowEvents }
			defaultSorted={defaultSortedBy}
			>
			</BootstrapTable>
      </CardBody>
    </Card>
  );
};

export default ListGroups;
